// function checkInitialState(initialState) {
//   if (
//     process.env.NODE_ENV == "development" &&
//     Object.keys(initialState).length == 0
//   ) {
//     throw new Error(`No initial state given, cannot reset.`);
//   }
// }

export function simpleReducer(state, action) {
  const { type, ...rest } = action;
  switch (type) {
    case 'UPDATE_ATTRIBUTE':
      return { ...state, ...rest };
    // case "RESET_ATTRIBUTES":
    //   checkInitialState(initialState);
    //   return { ...initialState };
    default:
      throw new Error(`${action.type} not implemented in simple reducer.`);
  }
}
