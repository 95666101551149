import { createTheme } from '@material-ui/core/styles';

export const PRIMARY_MAIN = '#15CD48';
export const PRIMARY_TEXT = '#171818';
export const SECONDARY_TEXT = '#7B7B7B';
export const ERROR = '#EF5353';
export const ERROR_LIGHT = '#e57373';
export const WARNING = '#EFBC05';
export const SUCCESS = '#7BCA56';
export const GREY_LIGHT = '#f5f5f5';

const theme = createTheme({
  typography: {
    fontSize: 16,
  },
  palette: {
    primary: {
      main: PRIMARY_MAIN,
    },
  },
});

export { theme };
