import { Box, Dialog, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import React, { useContext } from 'react';
import ReactHtmlParser from 'react-html-parser';
import { useTranslation } from 'react-i18next';

import { BaseURL } from '../../../API';
import { CurrentUserProviderContext } from '../../current_user_provider';

export const SupplierDescriptionModal = ({ isOpen, closeAction }) => {
  const { currentUser } = useContext(CurrentUserProviderContext);
  const { t } = useTranslation();

  return (
    <Dialog
      onClose={() => closeAction()}
      disableEscapeKeyDown
      fullWidth
      maxWidth='sm'
      open={isOpen}
    >
      <Box className='flex justify-content-end'>
        <IconButton onClick={() => closeAction()}>
          <CloseIcon />
        </IconButton>
      </Box>

      <Box className='flex col modal info-modal'>
        {currentUser.unit && (
          <>
            <h4 className='flex col h4 m-b-16'>{currentUser.selectedProduct.name}</h4>

            <h3 className='flex col h3 m-b-32'>{currentUser.unit.name}</h3>

            <Box className='flex col m-b-18'>
              {ReactHtmlParser(currentUser.unit.short_description)}
            </Box>

            <Box className='flex col m-b-32'>{ReactHtmlParser(currentUser.unit.description)}</Box>
          </>
        )}
        <Box className='flex align-items-center justify-content-center m-t-16'>
          <button className='btn-outlined  reversed btn-steps m-b-12' onClick={() => closeAction()}>
            OK
          </button>
        </Box>
      </Box>
    </Dialog>
  );
};
