import React, { useContext, useState } from 'react';
import Autocomplete from 'react-google-autocomplete';
import { useTranslation } from 'react-i18next';

import { updateAction } from '../../../../action_creators/simple_actions';
import { useNotify } from '../../../../hooks/use_notify';
import { getGeocodePlace } from '../../../../utils/geocode';
import { CurrentUserProviderContext } from '../../../current_user_provider';

export const BillingAutocomplete = () => {
  const { currentUser, updateCurrentUser } = useContext(CurrentUserProviderContext);

  const { t } = useTranslation();
  const [notify] = useNotify();

  const [value, setValue] = useState('');

  const updateBillingState = async (place) => {
    updateCurrentUser(updateAction({ isLoading: true }));
    try {
      const { address, addressFields } = await getGeocodePlace(place.geometry.location.toJSON());

      updateCurrentUser(
        updateAction({
          billingAddress: address,
        }),
      );

      if (currentUser.billingAddress.street !== addressFields.street) {
        updateCurrentUser(
          updateAction({
            billingAddressFields: addressFields,
          }),
        );
      }

      setValue(address.address ?? '');
      updateCurrentUser(updateAction({ isLoading: false }));
    } catch {
      notify(t('notifications.something-went-wrong'), 'error');
      updateCurrentUser(updateAction({ isLoading: false }));
    }
  };

  return (
    <label className='flex col form-input search m-t-32 m-b-32'>
      <Autocomplete
        placeholder={t('forms.placeholders.start-type-address')}
        onPlaceSelected={updateBillingState}
        options={{
          types: ['address'],
        }}
        value={value}
        onChange={(event) => setValue(event.target.value)}
        className='m-b-6'
      />

      <span className='input-icon search' />
    </label>
  );
};
