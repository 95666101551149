import { Box, Dialog, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import courier from '../../../assets/icons/courier.svg';
import self from '../../../assets/icons/self.svg';
import transport from '../../../assets/icons/transport.svg';
import { CurrentUserProviderContext } from '../../current_user_provider';

export const DeliveryModal = ({ isOpen, closeAction }) => {
  const { t } = useTranslation();
  const { currentUser } = useContext(CurrentUserProviderContext);

  return (
    <Dialog
      onClose={() => closeAction()}
      disableEscapeKeyDown
      fullWidth
      maxWidth='md'
      open={isOpen}
    >
      <Box className='flex justify-content-end'>
        <IconButton onClick={() => closeAction()}>
          <CloseIcon />
        </IconButton>
      </Box>

      <Box className='flex col modal info-modal'>
        <Box className='flex col m-b-32'>
          {currentUser.supplierTruckTypes ? (
            <>
              <Box className='flex align-items-center m-b-32'>
                <img src={transport} className='modal-logo m-r-32' />
                <h1 className='h1'>{t('modals.delivery.title')}</h1>
              </Box>

              {currentUser.supplierTruckTypes.map((truck) => {
                return (
                  <Box className='flex align-items-start info-item py-24' key={truck.id}>
                    <img src={truck.image} className='product-image m-r-32' />
                    <Box className='flex justify-content-between align-items-start w-100 content'>
                      <Box className='left'>
                        <span className='body-text-3 m-b-8'>{truck.truck_type}</span>
                        <Box className='text-secondary m-b-16'>
                          {t('modals.delivery.dimensions')}
                          {`${truck.height} x ${truck.width} x ${truck.length}`}
                          {t('modals.delivery.m')}
                        </Box>
                        <p>{truck.description}</p>
                      </Box>
                      <Box className='flex align-items-end right'>
                        <span className='body-text-5'>
                          {t('modals.delivery.from')}
                          {'\u00A0'}
                        </span>
                        <span className='body-text-2 text-extra-bold'>{truck.price_from} €</span>
                      </Box>
                    </Box>
                  </Box>
                );
              })}
            </>
          ) : (
            ''
          )}
        </Box>

        <Box className='flex col m-b-32'>
          {currentUser.courierTypes && currentUser.courierInfo.available ? (
            <>
              <Box className='flex align-items-center m-b-32'>
                <img src={courier} className='modal-logo m-r-32' />
                <h1 className='h1'>{t('modals.delivery.courier-title')}</h1>
              </Box>
              {currentUser.courierTypes.map((type) => {
                return (
                  <Box className='flex align-items-start info-item py-24' key={type.id}>
                    <img src={type.image} className='product-image m-r-32' />
                    <Box className='flex justify-content-between align-items-start w-100 content'>
                      <Box className='left'>
                        <span className='body-text-3 m-b-8'>
                          {type.name || t('modals.delivery.courier-delivery')}
                        </span>
                        <Box className='text-secondary m-b-16'>
                          {t('modals.delivery.dimensions')}
                          {`${type.height} x ${type.width} x ${type.length}`}
                          {t('modals.delivery.m')}
                        </Box>
                        <p>{type.description}</p>
                      </Box>
                      <Box className='flex align-items-end right'>
                        <span className='body-text-5'>
                          {t('modals.delivery.from')}
                          {'\u00A0'}
                        </span>
                        <span className='body-text-2 text-extra-bold'>{type.price} €</span>
                      </Box>
                    </Box>
                  </Box>
                );
              })}
            </>
          ) : (
            ''
          )}
        </Box>

        <Box className='flex col m-b-32'>
          {currentUser.selfPickupInfo && currentUser.selfPickupInfo.available ? (
            <>
              <Box className='flex align-items-center m-b-32'>
                <img src={self} className='modal-logo m-r-32' />
                <h1 className='h1'>{t('modals.delivery.self-pickup-title')}</h1>
              </Box>
              {currentUser.selfPickupInfo.comment && (
                <Box className='flex align-items-start info-item py-24'>
                  <img className='product-image m-r-32' />
                  <Box className='flex justify-content-between align-items-start w-100 content'>
                    <Box className='left'>
                      <p>{currentUser.selfPickupInfo.comment}</p>
                    </Box>
                    <Box className='flex align-items-end right'></Box>
                  </Box>
                </Box>
              )}
            </>
          ) : (
            ''
          )}
        </Box>
      </Box>
    </Dialog>
  );
};
