import axios from 'axios';

export const BaseURL =
  process.env['REACT_APP_SERVER'] == 'staging' ? 'https://staging.roadly.ee' : 'https://roadly.ee';

export default axios.create({
  baseURL: BaseURL,
  responseType: 'json',
  withCredentials: true,
  headers: {
    'Cache-Control': 'no-store, must-revalidate',
    'Content-Type': 'application/json',
  },
});
