import { Box } from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import API from '../../../API';
import { updateAction } from '../../../action_creators/simple_actions';
import { useNotify } from '../../../hooks/use_notify';
import { useOuterClick } from '../../../hooks/use_outer_click';
import { CurrentUserProviderContext } from '../../current_user_provider';
import { DeliveryModal } from '../../modals/';
import { StepTitle } from '../../step_title';
import { StepsNav } from '../../steps_nav';

export const Delivery = () => {
  const { t } = useTranslation();
  const [notify] = useNotify();
  const history = useHistory();
  const { currentUser, updateCurrentUser } = useContext(CurrentUserProviderContext);

  const [isSelectOpen, setIsSelectOpen] = useState(false);

  const selectOuterClick = useOuterClick((e) => {
    isSelectOpen && setIsSelectOpen(false);
  });

  const clearingNextStepState = () => {
    updateCurrentUser(
      updateAction({
        finishedStep: 3,
        isTimerStarted: false,
        availableSlots: null,
        selectedDate: null,
        selectedTimeSlot: null,
        banks: [],
        selectedBank: null,

        supplierSelfPickUp: null,

        quantityPerTruckJSON: null,
      }),
    );
  };

  const selfPickup = {
    id: 'self_pickup',
    name: t('step-4.self-pickup'),
    price_from: 0,
  };

  const courierDelivery = {
    id: 'courier',
    name: t('step-4.courier-delivery'),
    price_from: 0,
  };

  const noDelivery = {
    id: 'no_delivery',
    name: t('step-4.no-delivery'),
    price_from: 0,
  };

  const truckTypes = () => {
    const type = currentUser.selectedSupplier.supplier_unit_packing_types.find((type) => {
      return type.packing_type_id === currentUser.selectedPackingType.id;
    });

    const truckTypesArray = [];
    currentUser.truckTypes.length && truckTypesArray.push(...currentUser.truckTypes);
    type.self_pickup && truckTypesArray.push(selfPickup);
    type.courier_available &&
      truckTypesArray.push({
        ...courierDelivery,
        price_from: currentUser.courierInfo.info.one_packing_price,
      });
    !currentUser.truckTypes.length &&
      !type.self_pickup &&
      !type.courier_available &&
      truckTypesArray.push(noDelivery);

    return truckTypesArray;
  };

  const increaseQuantity = (index) => {
    const currentPerTruck = currentUser.quantityPerTruck;
    const quantity = parseFloat(currentUser.selectedQuantity);
    const step = parseFloat(currentUser.selectedPackingType.range_step);

    const val = !currentUser.selectedPackingType.by_weight
      ? quantity + step
      : Number((quantity + step).toFixed(1));

    const quantityPerTruckTmp = JSON.parse(JSON.stringify(currentPerTruck));

    !currentUser.selectedPackingType.by_weight
      ? (quantityPerTruckTmp[index].truckQuantity = currentPerTruck[index].truckQuantity + step)
      : (quantityPerTruckTmp[index].truckQuantity = Number(
          (parseFloat(currentPerTruck[index].truckQuantity) + step).toFixed(1),
        ));

    updateCurrentUser(
      updateAction({
        changedQuantity: true,
        selectedQuantity: val,
        quantityPerTruck: quantityPerTruckTmp,
      }),
    );
  };

  const decreaseQuantity = (index) => {
    const currentPerTruck = currentUser.quantityPerTruck;
    const quantity = Number(parseFloat(currentUser.selectedQuantity));
    const step = Number(parseFloat(currentUser.selectedPackingType.range_step));

    if (currentPerTruck[index].truckQuantity - step < step) {
      removeTruck(index);
    } else {
      const val = !currentUser.selectedPackingType.by_weight
        ? quantity - step
        : Number((quantity - step).toFixed(1));

      const quantityPerTruckTmp = JSON.parse(JSON.stringify(currentPerTruck));
      quantityPerTruckTmp[index].truckQuantity = currentPerTruck[index].truckQuantity - step;

      !currentUser.selectedPackingType.by_weight
        ? (quantityPerTruckTmp[index].truckQuantity = currentPerTruck[index].truckQuantity - step)
        : (quantityPerTruckTmp[index].truckQuantity = Number(
            parseFloat((currentPerTruck[index].truckQuantity - step).toFixed(1)),
          ));

      updateCurrentUser(
        updateAction({
          changedQuantity: true,
          selectedQuantity: val,
          quantityPerTruck: quantityPerTruckTmp,
        }),
      );
    }
  };

  const summaryQuantityAllTrucks = currentUser.quantityPerTruck.reduce(
    (acc, curr) => acc + curr.truckQuantity,
    0,
  );

  const calcAvailableQuantity = currentUser.currentMaxOrder - summaryQuantityAllTrucks;

  const addTruck = () => {
    updateCurrentUser(
      updateAction({
        changedQuantity: true,
        selectedQuantity: (
          Number(currentUser.selectedQuantity) + currentUser.selectedPackingType.range_step
        ).toFixed(1),
        trucks: [...currentUser.trucks, currentUser.selectedTruckType],
        quantityPerTruck: [
          ...currentUser.quantityPerTruck,
          { truckQuantity: currentUser.selectedPackingType.range_step },
        ],
      }),
    );
  };

  const removeTruck = (index) => {
    const trucksNew = JSON.parse(JSON.stringify(currentUser.trucks));
    const quantityPerTruckNew = JSON.parse(JSON.stringify(currentUser.quantityPerTruck));

    trucksNew.splice(index, 1);
    quantityPerTruckNew.splice(index, 1);

    updateCurrentUser({
      changedQuantity: true,
      selectedQuantity: Number(
        (currentUser.selectedQuantity - currentUser.quantityPerTruck[index].truckQuantity).toFixed(
          1,
        ),
      ),
      trucks: trucksNew,
      quantityPerTruck: quantityPerTruckNew,
    });
  };

  const compareWithMin = Number(currentUser.selectedQuantity) === currentUser.currentMinOrder;
  const compareWithMax = Number(currentUser.selectedQuantity) === currentUser.currentMaxOrder;

  const checkTruckMax = (index) =>
    currentUser.quantityPerTruck[index].truckQuantity === currentUser.selectedTruckType.max_for_car;

  const checkDisableMinus = compareWithMin;

  const checkDisablePlus = (index) => {
    return !!compareWithMax || !!checkTruckMax(index);
  };

  const checkDisableRemove = (index) => {
    const summaryWithoutCurrent =
      summaryQuantityAllTrucks - currentUser.quantityPerTruck[index].truckQuantity;

    return summaryWithoutCurrent < currentUser.currentMinOrder;
  };

  const isAnyTruckAvailable = (arr) => arr.filter((truck) => truck.max_for_car).length;

  const findMostSuitableTruck = (arr) => {
    const allRelevant = arr.filter((truck) => truck.max_for_car >= currentUser.selectedQuantity);
    return allRelevant.sort((a, b) => a.max_for_car - b.max_for_car)[0];
  };

  const findClosestTruckType = (arr) =>
    arr.reduce((acc, curr) => (acc.max_for_car > curr.max_for_car ? acc : curr));

  const calcArrivals = (obj) => Math.ceil(currentUser.selectedQuantity / obj.max_for_car);

  const calcTrucks = (arr) => {
    const truck = !currentUser.selectedTruckType
      ? findClosestTruckType(arr)
      : currentUser.selectedTruckType;
    let trucksTmp = [];
    let quantityPerTruckTmp = [];

    for (let i = 0; i < calcArrivals(truck); i++) {
      trucksTmp.push(truck);
    }

    for (let i = 0; i < trucksTmp.length; i++) {
      const rest = currentUser.selectedPackingType.by_weight
        ? Number(
            (
              parseFloat(currentUser.selectedQuantity) -
              (trucksTmp.length - 1) * truck.max_for_car
            ).toFixed(1),
          )
        : currentUser.selectedQuantity - (trucksTmp.length - 1) * truck.max_for_car;

      i + 1 !== trucksTmp.length
        ? quantityPerTruckTmp.push({
            truckQuantity: truck.max_for_car,
          })
        : quantityPerTruckTmp.push({
            truckQuantity: rest,
          });
    }
    return { selectedTruckType: truck, trucks: trucksTmp, quantityPerTruck: quantityPerTruckTmp };
  };

  const selectTruckType = (arr) => {
    if (isAnyTruckAvailable(arr)) {
      const truck = findMostSuitableTruck(arr);
      if (truck && !currentUser.selectedTruckType) {
        updateCurrentUser(
          updateAction({
            selectedTruckType: truck,
            trucks: [truck],
            quantityPerTruck: [currentUser.selectedQuantity],
          }),
        );
      } else {
        updateCurrentUser(
          updateAction({
            selectedTruckType: currentUser.selectedTruckType
              ? currentUser.selectedTruckType
              : calcTrucks(arr).selectedTruckType,

            trucks: calcTrucks(arr).trucks,
            quantityPerTruck: calcTrucks(arr).quantityPerTruck,
          }),
        );
      }
    } else {
      updateCurrentUser(
        updateAction({
          selfPickup: true,
        }),
      );
    }
  };

  const unitPrice = () => {
    const type = currentUser.selectedSupplier.supplier_unit_packing_types.find(
      (type) => type.packing_type_id === currentUser.selectedPackingType.id,
    );
    return type.discount_price ? type.discount_price : type.price;
  };

  const supplierUnitPackingTypeId = currentUser.selectedSupplier.supplier_unit_packing_types.find(
    (type) => type.packing_type_id === currentUser.selectedPackingType.id,
  ).id;
  const supplierUnitId = currentUser.selectedSupplier.supplier_unit_packing_types.find(
    (type) => type.packing_type_id === currentUser.selectedPackingType.id,
  ).supplier_unit_id;

  const nextStepActions = () => {
    updateCurrentUser(
      updateAction({
        currentStep: 5,
        finishedStep: currentUser.finishedStep > 4 ? currentUser.finishedStep : 4,
      }),
    );

    history.push('/aeg');
  };

  const backStepActions = () => {
    updateCurrentUser(
      updateAction({
        currentStep: 3,
      }),
    );

    history.push('/tootja');
  };

  const getData = () => {
    API.post('/api/v1/orders/delivery', {
      order: {
        ...currentUser.order,
        supplier_id: currentUser.selectedSupplier.id,
        supplier_name: currentUser.selectedSupplier.name,
        supplier_unit_id: supplierUnitId,
        supplier_unit_packing_type_id: supplierUnitPackingTypeId,
        packing_type: currentUser.selectedPackingType.name,
        unit_price: unitPrice(),
        quantity: currentUser.selectedQuantity,
      },
    })
      .then((res) => {
        const data = res.data.data;
        updateCurrentUser(
          updateAction({
            order: data.order,
            truckTypes: data.truck_types,
            supplierTruckTypes: data.supplier_truck_types,
            courierTypes: data.courier_types,
            courierInfo: data.courier_delivery,
            selfPickupDistance: data.self_pickup.available
              ? data.self_pickup.info.total_distance
              : '',
            selfPickupInfo: data.self_pickup,
            isloggedIn: data.logged_in,
            isLoading: false,
          }),
        );

        if (!data.truck_types.length && data.self_pickup.available) {
          updateCurrentUser(
            updateAction({
              selectedTruckType: selfPickup,
              selfPickup: true,
            }),
          );
        }

        if (!data.truck_types.length && data.courier_delivery.available) {
          updateCurrentUser(
            updateAction({
              selectedTruckType: {
                ...courierDelivery,
                price_from: data.courier_delivery.info.one_packing_price,
              },
              courierInfo: data.courier_delivery,
              courier: true,
            }),
          );
        }
      })
      .catch((err) => {
        if (err.response?.status === 401) {
          updateCurrentUser(
            updateAction({
              isTimerModalOpen: true,
              timerModalCustomAction: false,
            }),
          );
        } else {
          notify(t('notifications.something-went-wrong'), 'error');
        }
        updateCurrentUser(updateAction({ isLoading: false }));
      });
  };

  useEffect(() => {
    if (currentUser.currentStep > currentUser.finishedStep) {
      updateCurrentUser(updateAction({ isLoading: true }));
      getData();
    }
  }, []);

  useEffect(() => {
    if (currentUser.truckTypes.length && currentUser.currentStep > currentUser.finishedStep) {
      selectTruckType(currentUser.truckTypes);
    }
  }, [currentUser.truckTypes, currentUser.selectedTruckType, currentUser.courierInfo]);

  useEffect(() => {
    if (currentUser.quantityPerTruck.length) {
      const trucksLoading = currentUser.quantityPerTruck.reduce(
        (acc, curr, index) => ({
          ...acc,
          [index]: String(curr.truckQuantity),
        }),
        {},
      );

      updateCurrentUser(
        updateAction({
          quantityPerTruckJSON: trucksLoading,
        }),
      );
    }
  }, [currentUser.quantityPerTruck]);

  return (
    <>
      {currentUser.selectedTruckType && (
        <Box className='delivery-step'>
          <StepTitle title={t('steps-names.step-4')} num='4' />

          <Box className='flex col summary m-b-60'>
            <Box className='text-secondary with-icon quantity m-b-16 m-r-8'>
              {t('step-4.totals')}{' '}
              <span className='text-green text-bold body-text-3'>
                {currentUser.selectedQuantity}
                {currentUser.selectedPackingType.by_weight ? ' t' : ''} x{' '}
                {currentUser.order.unit_price} € ={' '}
                {parseFloat(
                  (currentUser.order.unit_price * currentUser.selectedQuantity).toFixed(2),
                )}{' '}
                €
              </span>
            </Box>
            {currentUser.selectedTruckType.id !== 'self_pickup' && (
              <Box className='text-secondary with-icon delivery m-b-16 m-r-8'>
                {t('step-4.price')}{' '}
                <span className='text-green text-bold body-text-3'>
                  {currentUser.selectedTruckType.id === 'courier'
                    ? `${currentUser.courierInfo.info.full_price}€  (${
                        currentUser.courierInfo.info.places
                      } ${t('step-4.places')})`
                    : t('step-4.choose-delivery-time')}
                </span>
              </Box>
            )}
            {currentUser.selectedTruckType.id === 'self_pickup' && (
              <Box className='text-secondary with-icon distance m-b-16 m-r-8'>
                {t('step-4.distance')}{' '}
                <span className='text-green text-bold body-text-3'>
                  {currentUser.selfPickupDistance + t('step-4.km')}
                </span>
              </Box>
            )}
          </Box>

          {
            <Box className='flex col form-input select-input m-b-16'>
              <span className='display-inline-block text-medium m-b-8'>
                {t('step-4.choose-delivery')}
              </span>
              <span className={`input-icon dropdown ${isSelectOpen ? 'active' : ''}`} />

              <Box
                className='flex align-items-center cursor-pointer select-box'
                ref={selectOuterClick}
                onClick={() => setIsSelectOpen(!isSelectOpen)}
              >
                <span>{currentUser.selectedTruckType.name}</span>

                {/*<span className='body-text-1 text-bold'>*/}
                {/*    {t('step-4.from')}{currentUser.selectedTruckType.price_from} €*/}
                {/*</span>*/}
              </Box>

              {isSelectOpen && (
                <Box className='flex col options-box'>
                  {truckTypes().map((option, index) => {
                    return (
                      <Box
                        className='option'
                        onClick={() => {
                          clearingNextStepState();
                          updateCurrentUser(
                            updateAction({
                              selectedTruckType: option,
                            }),
                          );
                          setIsSelectOpen(!isSelectOpen);
                        }}
                        key={index}
                      >
                        {option.name}
                        {/*- {option.id !== 'self_pickup' && t('step-4.from')}{option.price_from} €*/}
                      </Box>
                    );
                  })}
                </Box>
              )}
            </Box>
          }

          <Box
            className='body-text-1 text-bold text-green with-icon question opacity-hover cursor-pointer m-b-60'
            onClick={() =>
              updateCurrentUser(
                updateAction({ isDeliveryModalOpen: !currentUser.isDeliveryModalOpen }),
              )
            }
          >
            {t('step-4.information')}
          </Box>

          {currentUser.selectedTruckType.id !== 'self_pickup' &&
          currentUser.selectedTruckType.id !== 'courier' &&
          currentUser.trucks.length ? (
            <>
              <Box className='body-text-1 text-bold m-b-8'>
                {t('step-4.amount-of-rides')} {currentUser.trucks.length}
              </Box>
              {currentUser.trucks.map((truck, index) => {
                return (
                  <Box
                    key={index}
                    className='card card-truck-type flex align-items-center justify-content-between m-b-32'
                  >
                    <Box className='flex align-items-center'>
                      <img src={truck.image} className='truck-img m-r-8' />
                      <span className='body-text-3 text-medium'>{truck.name}</span>
                    </Box>

                    <Box className='flex align-items-center truck-nav'>
                      <label className='product-input-number m-r-16'>
                        <button
                          type='button'
                          className='text-green'
                          onClick={() => {
                            clearingNextStepState();
                            decreaseQuantity(index);
                          }}
                          disabled={checkDisableMinus}
                        >
                          &ndash;
                        </button>
                        <span className='text-extra-bold value'>{`
                                                    ${
                                                      currentUser.quantityPerTruck[index]
                                                        .truckQuantity
                                                    }
                                                    ${
                                                      currentUser.selectedPackingType.by_weight
                                                        ? ' t'
                                                        : ''
                                                    }`}</span>
                        <button
                          type='button'
                          className='text-green'
                          onClick={() => {
                            clearingNextStepState();
                            increaseQuantity(index);
                          }}
                          disabled={checkDisablePlus(index)}
                        >
                          +
                        </button>
                      </label>
                      <button
                        className='btn-close'
                        onClick={() => {
                          clearingNextStepState();
                          removeTruck(index);
                        }}
                        disabled={checkDisableRemove(index)}
                      />
                    </Box>
                  </Box>
                );
              })}

              <button
                className='flex align-items-center justify-content-center cursor-pointer m-b-32 add-truck'
                onClick={() => {
                  addTruck();
                  clearingNextStepState();
                }}
                disabled={!calcAvailableQuantity}
              >
                <span className='body-text-3 text-bold text-primary'>{t('step-4.add-new')}</span>
              </button>
            </>
          ) : null}

          <DeliveryModal
            isOpen={currentUser.isDeliveryModalOpen}
            closeAction={() =>
              updateCurrentUser(
                updateAction({
                  isDeliveryModalOpen: !currentUser.isDeliveryModalOpen,
                }),
              )
            }
          />
        </Box>
      )}

      <StepsNav nextStepAction={() => nextStepActions()} backStepAction={() => backStepActions()} />
    </>
  );
};
