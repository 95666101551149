import { Box } from '@material-ui/core';
import moment from 'moment';
import localization from 'moment/locale/et';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import Slider from 'react-slick';

import API from '../../../../API';
import { updateAction } from '../../../../action_creators/simple_actions';
import noTime from '../../../../assets/images/no-time-slot.svg';
import { useNotify } from '../../../../hooks/use_notify';
import { CurrentUserProviderContext } from '../../../current_user_provider';
import { StepsNav } from '../../../steps_nav';

export const Trucks = () => {
  const slider = useRef();

  const { t } = useTranslation();
  const [notify] = useNotify();
  const history = useHistory();

  const sliderSettings = {
    infinite: false,
    speed: 300,
    centerMode: false,
    swipeToSlide: true,
    slidesToScroll: 3,
    variableWidth: true,
  };

  moment.updateLocale('et', localization);

  const { currentUser, updateCurrentUser } = useContext(CurrentUserProviderContext);

  const [availableDates, setAvailableDates] = useState(null);

  const findNextDays = () => {
    let dates = [];

    for (let i = 1; i <= 30; i++) {
      dates.push(moment().add(i, 'days'));
    }

    setAvailableDates(dates);
  };

  const formatDate = (date, format) => moment(date).format(format);

  const formatTime = (time) => {
    const hour = parseInt(time.split(':')[0]);
    const formatHours = (arg) => (arg > 9 ? `${arg}` : `0${arg}`);
    return `${formatHours(hour)}:00-${formatHours(hour + 1)}:00`;
  };

  const clearingNextStepState = () => {
    updateCurrentUser(
      updateAction({
        isTimerStarted: false,
        finishedStep: 4,
        banks: [],
      }),
    );
  };

  const getData = (date) => {
    API.post(`/api/v1/orders/timing/delivery?date=${date}`, {
      order: {
        ...currentUser.order,
        truck_type_id: currentUser.selectedTruckType.id,
        self_pickup: false,
        courier_delivery: false,
        trucks_quantity: currentUser.trucks.length,
        trucks_loading: currentUser.quantityPerTruckJSON,
      },
    })
      .then((res) => {
        const data = res.data.data;
        updateCurrentUser(
          updateAction({
            order: data.order,
            availableSlots: data.info.time_slots,
            isloggedIn: data.logged_in,
            isLoading: false,
          }),
        );
      })
      .catch((err) => {
        if (err.response.status === 401) {
          updateCurrentUser(
            updateAction({
              isTimerModalOpen: true,
              timerModalCustomAction: false,
            }),
          );
        } else {
          notify(t('notifications.something-went-wrong'), 'error');
        }
        updateCurrentUser(updateAction({ isLoading: false }));
      });
  };

  const checkDriverAvailability = () => {
    updateCurrentUser(updateAction({ isLoading: true }));

    API.post(`/api/v1/orders/timing/delivery?date=${currentUser.selectedDate}`, {
      order: { ...currentUser.order },
    })
      .then((res) => {
        const data = res.data.data;

        const period = data.info.time_slots.find(
          (slot) => slot.period === currentUser.selectedTimeSlot.period,
        );
        const driverId = period.info ? period.info.driver_id : null;
        if (period && period.info && driverId === currentUser.selectedTimeSlot.info.driver_id) {
          updateCurrentUser(
            updateAction({
              currentStep: 6,
              finishedStep: currentUser.finishedStep > 5 ? currentUser.finishedStep : 5,
            }),
          );

          history.push('/maksmine');
        } else {
          getData(currentUser.selectedDate);
          updateCurrentUser(
            updateAction({
              selectedTimeSlot: null,
            }),
          );
          notify(t('notifications.time-slot-warning'), 'warning');
        }

        updateCurrentUser(updateAction({ isLoading: false }));
      })
      .catch((err) => {
        notify(t('notifications.something-went-wrong'), 'error');
        updateCurrentUser(updateAction({ isLoading: false }));
      });
  };

  const nextStepActions = () => {
    if (!currentUser.selectedDate || !currentUser.selectedTimeSlot) {
      !currentUser.selectedDate && notify(t('notifications.date'), 'warning');
      !currentUser.selectedTimeSlot &&
        currentUser.selectedDate &&
        notify(t('notifications.time'), 'warning');
    } else {
      checkDriverAvailability();
    }
  };

  const backStepActions = () => {
    updateCurrentUser(
      updateAction({
        currentStep: 4,
      }),
    );

    history.push('/transport');
  };

  useEffect(() => {
    findNextDays();
  }, []);

  return (
    <>
      <Box className='flex col summary m-b-60'>
        <Box className='text-secondary with-icon quantity m-b-16 m-r-8'>
          {t('step-5.totals')}{' '}
          <span className='text-green text-bold body-text-3'>
            {currentUser.selectedQuantity}
            {currentUser.selectedPackingType.by_weight ? ' t' : ''} x {currentUser.order.unit_price}{' '}
            € ={' '}
            {parseFloat((currentUser.order.unit_price * currentUser.selectedQuantity).toFixed(2))} €
          </span>
        </Box>
        <Box className='text-secondary with-icon delivery m-b-16 m-r-8'>
          {t('step-5.price')}{' '}
          <span className='body-text-3 text-bold text-green'>
            {currentUser.selectedTimeSlot
              ? `${currentUser.selectedTimeSlot.info.price} €`
              : t('step-5.choose-time-2')}
          </span>
        </Box>
      </Box>

      <Box className='body-text-3 text-bold m-b-28'>{t('step-5.choose-time')}</Box>

      <Box className='flex nowrap m-b-60 position-relative date'>
        {availableDates && (
          <Slider ref={slider} {...sliderSettings}>
            {availableDates.map((item, index) => {
              const isWeekend = item.day() === 6 || item.day() === 0;
              const weekendClass = isWeekend ? 'weekend' : '';

              return (
                <Box key={index} className='no-outline'>
                  <Box
                    className={`flex col nowrap align-items-center cursor-pointer item ${weekendClass} ${
                      currentUser.selectedDate === formatDate(item, 'DD.MM.YYYY') ? 'active' : ''
                    }`}
                    onClick={() => {
                      updateCurrentUser(
                        updateAction({
                          isLoading: true,
                          selectedDate: formatDate(item, 'DD.MM.YYYY'),
                          selectedTimeSlot: null,
                        }),
                      );
                      clearingNextStepState();
                      getData(formatDate(item, 'DD.MM.YYYY'));
                    }}
                    key={index}
                  >
                    <span className='text-uppercase text-bold text-secondary body-text-6'>
                      {formatDate(item, 'MMM')}
                    </span>
                    <span className='body-text-4 text-medium'>{formatDate(item, 'D')}</span>
                    <span className='text-uppercase text-bold text-secondary body-text-6'>
                      {formatDate(item, 'dd')}
                    </span>
                  </Box>
                </Box>
              );
            })}
          </Slider>
        )}
      </Box>

      {currentUser.availableSlots ? (
        <Box className='flex time-slots'>
          {currentUser.availableSlots.map((item, index) => {
            return (
              <Box
                key={index}
                className={`flex col justify-content-center align-items-center text-bold m-r-32 m-b-32 item
                                                    ${!item.info ? 'disabled' : ''}
                                                      ${
                                                        currentUser.selectedTimeSlot
                                                          ? currentUser.selectedTimeSlot.period ===
                                                            item.period
                                                            ? 'active'
                                                            : ''
                                                          : false
                                                      }
                                                    `}
                onClick={() => {
                  clearingNextStepState();
                  updateCurrentUser(
                    updateAction({
                      selectedTimeSlot: item,
                      isTimerStarted: true,
                    }),
                  );
                }}
              >
                <Box>{formatTime(item.period)}</Box>
                <Box className='text-bold delivery-price'>
                  {item.info ? `${item.info.price} €` : ''}
                </Box>
              </Box>
            );
          })}
        </Box>
      ) : (
        ''
      )}

      {!currentUser.selectedDate && !currentUser.isLoading ? (
        <Box className='flex col align-items-center no-time-slot'>
          <img src={noTime} className='no-time-img m-b-32' />
          <span className='body-text-3 text-bold'>{t('step-5.no-time')}</span>
        </Box>
      ) : (
        ''
      )}

      <StepsNav nextStepAction={() => nextStepActions()} backStepAction={() => backStepActions()} />
    </>
  );
};
