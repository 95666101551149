import { Box } from '@material-ui/core';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { CurrentUserProviderContext } from '../current_user_provider';

export const StepsNav = ({ nextStepAction, backStepAction }) => {
  const { t } = useTranslation();
  const { currentUser } = useContext(CurrentUserProviderContext);

  return (
    <Box className='steps-nav flex justify-content-between align-items-center'>
      <button
        className='btn-outlined with-arrow angle-arrow reversed btn-steps'
        disabled={currentUser.currentStep === 1}
        onClick={() => backStepAction()}
      >
        {t('buttons.back')}
      </button>
      <Box className='flex'>
        <span className='body-text-7 text-bold text-green'>{currentUser.currentStep}</span>
        <span className='body-text-7 text-secondary'>&ndash;</span>
        <span className='body-text-7 text-secondary'>7</span>
      </Box>
      <button
        className='btn-contained with-arrow angle-arrow btn-steps'
        onClick={() => nextStepAction()}
        disabled={currentUser.isLoading}
      >
        {t('buttons.next')}
      </button>
    </Box>
  );
};
