import React from 'react';
import { useTranslation } from 'react-i18next';

export const BasicInput = ({
  variant = 'default',
  id,
  label,
  value,
  placeholder,
  iconClass,
  name,
  error,
  onChange,
  onBlur,
  onInput,
  specialClasses,
  isReadOnly,
  elRef,
  ...other
}) => {
  return (
    <label ref={elRef} className={`flex col form-input ${specialClasses} ${error ? 'error' : ''}`}>
      <span className='display-inline-block text-medium m-b-8'>{label}</span>
      <input
        type='text'
        placeholder={placeholder}
        className='m-b-6'
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        onInput={onInput}
        name={name}
        readOnly={isReadOnly}
      />
      <span className={`input-icon ${iconClass}`} />
      <span className='error-msg'>{error}</span>
    </label>
  );
};
