import { Box } from '@material-ui/core';
import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { BaseURL } from '../../API';
import logo from '../../assets/icons/logo.svg';
import { CurrentUserProviderContext } from '../current_user_provider';

export const Header = () => {
  const { t } = useTranslation();
  const { currentUser } = useContext(CurrentUserProviderContext);

  return (
    <Box component='header' className='flex justify-content-between align-items-center header'>
      <Box className='logo'>
        <Link to={{ pathname: BaseURL || 'my.localhost:3000' }} target='_blank'>
          <img src={logo} className='logo' />
        </Link>
      </Box>

      <Box>
        <Link to={{ pathname: `${BaseURL}/kasutajad/logi-sisse` }} target='_blank'>
          <Box className='icon-link text-green opacity-hover user-name text-bold'>
            {currentUser.isloggedIn ? t('buttons.profile') : t('buttons.login')}
          </Box>
        </Link>
        <Link to={{ pathname: BaseURL }} target='_blank'>
          <Box className='btn-outlined btn-web-site dark m-l-20'>{t('buttons.go-to-web')}</Box>
        </Link>
      </Box>
    </Box>
  );
};
