import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { Dashboard } from './components/dashboard';
import { Category, Checkout, Delivery, Product, Supplier, Timing } from './components/steps';
import { OrderResult } from './components/steps/order_result/order_result';

export default function Routes() {
  return (
    <Switch>
      <Dashboard>
        <Route path={['/kategooriad', '/kategooriad:params']}>
          <Category />
        </Route>

        <Route exact path='/toode'>
          <Product />
        </Route>

        <Route exact path='/tootja'>
          <Supplier />
        </Route>

        <Route exact path='/transport'>
          <Delivery />
        </Route>

        <Route exact path='/aeg'>
          <Timing />
        </Route>

        <Route exact path='/maksmine'>
          <Checkout />
        </Route>

        <Route exact path='/aitah'>
          <OrderResult />
        </Route>

        {/*<Route exact path='*'>*/}
        {/*    <NotFound/>*/}
        {/*</Route>*/}
      </Dashboard>
    </Switch>
  );
}
