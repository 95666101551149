import React from 'react';

export const PackageRadio = ({ title, name, value, checked, onChange, specialClasses }) => {
  return (
    <label className={`package-radio ${specialClasses}`}>
      <input type='radio' name={name} value={value} checked={checked} onChange={() => onChange()} />
      <span className='checkmark'>
        <span>{title}</span>
      </span>
    </label>
  );
};
