import { Box, Button, Card, List } from '@material-ui/core';
import { AddCircle } from '@material-ui/icons';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

import API from '../../../../API';
import { updateAction } from '../../../../action_creators/simple_actions';
import { GoogleMapsAPI } from '../../../../client-config';
import { useNotify } from '../../../../hooks/use_notify';
import { CurrentUserProviderContext } from '../../../current_user_provider';
import { Address } from './address';
import { AddressModal } from './modal';

export const AddressChoose = () => {
  const { currentUser, updateCurrentUser } = useContext(CurrentUserProviderContext);
  const [notify] = useNotify();
  const { t } = useTranslation();

  const [show, setShow] = useState(false);

  const defaultAddress = {
    address: '',
    mapPosition: { lat: 59.179282, lng: 24.754781 },
  };

  const defaultAddressFields = {
    country: '',
    region: '',
    city: '',
    street: '',
    number: '',
    zip: '',
    comment: '',
  };

  const onAddAddressClick = () => {
    setShow(true);
  };

  const onNewSubmit = (main) => {
    updateCurrentUser(updateAction({ isLoading: true }));

    API.post('/api/v1/orders/addresses/new', {
      order: currentUser.order,
      new_address: {
        main: main,
        google_autocomplete: currentUser.shippingAddress.address,
        longitude: currentUser.shippingAddress.mapPosition.lng,
        latitude: currentUser.shippingAddress.mapPosition.lat,
        street: currentUser.shippingAddressFields.street,
        city: currentUser.shippingAddressFields.city,
        region: currentUser.shippingAddressFields.region,
        country: currentUser.shippingAddressFields.country,
        house: currentUser.shippingAddressFields.number,
        zip: currentUser.shippingAddressFields.zip,
        comment: currentUser.shippingAddressFields.comment,

        billing_google_autocomplete: currentUser.billingAddress.address,
        billing_street: currentUser.billingAddressFields.street,
        billing_city: currentUser.billingAddressFields.city,
        billing_country: currentUser.billingAddressFields.country,
        billing_house: currentUser.billingAddressFields.number,
        billing_region: currentUser.billingAddressFields.region,
        billing_zip: currentUser.billingAddressFields.zip,
        billing_like_shipping: currentUser.billingLikeShipping,
      },
    })
      .then((res) => {
        const data = res.data.data;

        updateCurrentUser(
          updateAction({
            order: data.order,
            addresses: data.user_addresses,
            mainAddressId: data.user_addresses.length
              ? data.user_addresses.find((item) => item.main === true).id
              : null,
          }),
        );
      })
      .catch(() => {
        notify(t('notifications.something-went-wrong'), 'error');
      })
      .finally(() => {
        document.dispatchEvent(new Event('shippingAddressUpdated'));
        updateCurrentUser(
          updateAction({
            isLoading: false,

            billingLikeShipping: true,

            shippingAddress: defaultAddress,
            shippingAddressFields: defaultAddressFields,
            billingAddress: defaultAddress,
            billingAddressFields: defaultAddressFields,
          }),
          setShow(false),
        );
      });
  };

  return (
    <div>
      <AddressModal
        googleMapURL={`https:maps.googleapis.com/maps/api/js?key=${GoogleMapsAPI}&libraries=places&language=et`}
        loadingElement={<Box />}
        show={show}
        setShow={setShow}
        title={t('step-1.add-new')}
        onSubmit={onNewSubmit}
      />
      <Card variant='outlined' square className='card-secondary'>
        <List>
          {currentUser.addresses.map((address) => (
            <Address key={address.id} address={address} />
          ))}
        </List>
        <Box className='flex justify-content-center align-items-center m-t-8'>
          <Button onClick={onAddAddressClick}>
            <AddCircle color='primary' className='m-r-8' />
            <p className='h2'>{t('step-1.add-new')}</p>
          </Button>
        </Box>
      </Card>
    </div>
  );
};
