import React from 'react';

export const BasicRadio = ({ title, name, checked, onChange, specialClasses }) => {
  return (
    <label className={`form-radio ${specialClasses}`}>
      <input type='radio' name={name} onChange={() => onChange()} checked={checked} />
      <span className='checkmark' />
      <span>{title}</span>
    </label>
  );
};
