import Geocode from 'react-geocode';

import { GoogleMapsAPI } from '../../client-config';

const getParam = (data, key, name_type = 'long_name') => {
  const item = data.find((item) => item.types.includes(key));
  return item ? item[name_type] : '';
};

export const parsePlaceResult = (position, result) => {
  const address = result.formatted_address ?? '';
  const addressArray = result.address_components;

  const country = getParam(addressArray, 'country');
  const countryShortName = getParam(addressArray, 'country', 'short_name');
  const region = getParam(addressArray, 'administrative_area_level_1');
  const city = getParam(addressArray, 'locality');
  const number = getParam(addressArray, 'street_number');
  const street = getParam(addressArray, 'route') || getParam(addressArray, 'premise');
  const zip = getParam(addressArray, 'postal_code');

  return {
    address: {
      address: address,
      mapPosition: position,
    },
    addressFields: {
      country: country,
      region: region,
      city: city,
      street: street,
      number: number,
      zip: zip,
    },
    countryShortName,
  };
};

export const getGeocodePlace = async (position) => {
  Geocode.setApiKey(GoogleMapsAPI);
  Geocode.setLanguage('et');
  const response = await Geocode.fromLatLng(position.lat, position.lng);

  const result = response.results[0];
  if (result) {
    return parsePlaceResult(position, result);
  } else {
    throw new Error('Geocode result not found');
  }
};
