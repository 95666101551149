import { Chip } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import React, { useContext, useState } from 'react';
import Autocomplete from 'react-google-autocomplete';
import { useTranslation } from 'react-i18next';

import { updateAction } from '../../../../action_creators/simple_actions';
import { GoogleMapsAPI } from '../../../../client-config';
import { useNotify } from '../../../../hooks/use_notify';
import { getGeocodePlace, parsePlaceResult } from '../../../../utils/geocode';
import { CurrentUserProviderContext } from '../../../current_user_provider';
import { AddressMap } from './map';

export const AddressSearch = () => {
  const [notify] = useNotify();
  const { t } = useTranslation();

  const { currentUser, updateCurrentUser } = useContext(CurrentUserProviderContext);

  const [value, setValue] = useState(currentUser.shippingAddress.address);
  const [showMap, setShowMap] = useState(!!value);

  const defaultAddress = {
    address: '',
    mapPosition: { lat: 59.179282, lng: 24.754781 },
  };

  const defaultAddressFields = {
    country: '',
    region: '',
    city: '',
    street: '',
    number: '',
    zip: '',
    comment: '',
  };

  const updateShippingAddress = (shippingAddress) => {
    updateCurrentUser(
      updateAction({
        shippingAddress,
      }),
    );
    setValue(shippingAddress.address);
    document.dispatchEvent(new Event('shippingAddressUpdated'));
  };

  const updateZoom = (zoom) => {
    updateCurrentUser(
      updateAction({
        mapZoomLevel: zoom,
      }),
    );
  };

  const updateShippingAddressFields = (shippingAddressFields) => {
    updateCurrentUser(
      updateAction({
        shippingAddressFields,
      }),
    );
  };

  const updateCenter = async (position) => {
    try {
      const { address, addressFields, countryShortName } = await getGeocodePlace(position);
      if (countryShortName === 'EE') {
        updateShippingAddress(address);
        updateShippingAddressFields({ ...addressFields, comment: '' });
      } else {
        notify(t('notifications.wrong-location'), 'warning');

        updateShippingAddress(defaultAddress);
        updateShippingAddressFields(defaultAddressFields);
      }
    } catch {
      notify(t('notifications.something-went-wrong'), 'error');

      updateShippingAddress({
        address: '',
        mapPosition: position,
      });
      updateShippingAddressFields(defaultAddressFields);
    }
  };

  const onPlaceSelected = (place) => {
    if (place && place.geometry?.location) {
      const { address, addressFields } = parsePlaceResult(place.geometry.location.toJSON(), place);
      updateShippingAddress(address);
      updateShippingAddressFields(addressFields);
      setShowMap(true);
    }
  };

  const onAddressDelete = () => {
    updateShippingAddress(defaultAddress);
    updateShippingAddressFields(defaultAddressFields);

    setShowMap(false);
  };

  return (
    <>
      <label className='flex col form-input search m-t-8 m-b-8'>
        <Autocomplete
          placeholder={t('forms.placeholders.start-type-address')}
          apiKey={GoogleMapsAPI}
          onPlaceSelected={onPlaceSelected}
          language='et'
          options={{
            types: [], // empty types means search all types, default cities
            componentRestrictions: { country: 'ee' },
          }}
          value={value}
          onChange={(event) => setValue(event.target.value)}
        />
        <span className='input-icon search' />
      </label>

      {currentUser.shippingAddress.address && (
        <Chip
          color='primary'
          variant='outlined'
          className='m-b-8 chip'
          label={currentUser.shippingAddress.address}
          onDelete={onAddressDelete}
          deleteIcon={<Close />}
        />
      )}

      {showMap && (
        <AddressMap
          center={currentUser.shippingAddress.mapPosition}
          updateCenter={updateCenter}
          zoom={currentUser.mapZoomLevel}
          updateZoom={updateZoom}
        />
      )}
    </>
  );
};
