import { Box } from '@material-ui/core';
import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { BaseURL } from '../../../../API';
import image from '../../../../assets/icons/finished-step.svg';
import { CurrentUserProviderContext } from '../../../current_user_provider';

export const ThankYou = () => {
  const { t } = useTranslation();

  const { currentUser } = useContext(CurrentUserProviderContext);

  useEffect(() => {
    document.title = t('steps-titles.thank-you');
  }, []);

  return (
    <Box className='flex justify-content-center align-items-center thank-you-step'>
      <Box className='flex col align-items-center content'>
        <img src={image} className='step-image m-b-60' />
        <Box className='m-b-16 text-extra-bold title'>{t('thank-you-step.thank-you')}</Box>
        <Box className='body-text-2 text-bold m-b-28 text-center'>
          {t('thank-you-step.your-order')}
          <span className='text-green'> #{currentUser.orderId}</span>{' '}
          {t('thank-you-step.successfully')}
        </Box>
        <Box className='body-text-3 text-secondary text-center m-b-60'>
          {t('thank-you-step.email-sent')} <br />
          {t('thank-you-step.follow-order')}
          {'\u00A0'}
          <span
            className='text-green text-underline opacity-hover cursor-pointer'
            onClick={() => (window.location.href = currentUser.profileUrl)}
          >
            {t('thank-you-step.your-profile')}
          </span>
        </Box>

        {currentUser.paymentMethod === 'invoice' && (
          <Box className='body-text-3 m-b-60'>
            <h1 className='h1 m-t-0 m-b-16'>{t('thank-you-step.bank-title')}</h1>
            <ul className='with-style m-b-48'>
              <li className='body-text-1 m-b-4'>
                {t('thank-you-step.recipient')}: <span className='text-bold'>Roadly OÜ</span>
              </li>
              <li className='body-text-1 m-b-4'>
                {t('thank-you-step.pank')}: <span className='text-bold'>LHV Pank</span>
              </li>
              <li className='body-text-1 m-b-4'>
                {t('thank-you-step.iban')}: <span className='text-bold'>EE737700771006423936</span>
              </li>
              <li className='body-text-1 m-b-4'>
                {t('thank-you-step.explanation')}:{' '}
                <span className='text-bold'>Ettemaksuarve #{currentUser.orderId}</span>
              </li>
            </ul>
            {t('thank-you-step.info')}: info@roadly.ee
          </Box>
        )}

        <button
          className='btn-contained with-arrow angle-arrow reversed'
          onClick={() => (window.location.href = BaseURL)}
        >
          {t('buttons.back-to-home')}
        </button>
      </Box>
    </Box>
  );
};
