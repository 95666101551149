import { Box } from '@material-ui/core';
import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import API from '../../../../API';
import { updateAction } from '../../../../action_creators/simple_actions';
import { useNotify } from '../../../../hooks/use_notify';
import { CurrentUserProviderContext } from '../../../current_user_provider';
import { StepsNav } from '../../../steps_nav';

export const Courier = () => {
  const [notify] = useNotify();
  const { t } = useTranslation();

  const history = useHistory();
  const { currentUser, updateCurrentUser } = useContext(CurrentUserProviderContext);

  const getData = () => {
    //remove selfPickup flag
    API.post('/api/v1/orders/timing/self_pickup', {
      order: {
        ...currentUser.order,
        self_pickup: false,
        courier_delivery: true,
      },
    })
      .then((res) => {
        const data = res.data.data;
        updateCurrentUser(
          updateAction({
            order: data.order,
            isloggedIn: data.logged_in,
            isLoading: false,
          }),
        );
      })
      .catch((err) => {
        if (err.response.status === 401) {
          updateCurrentUser(
            updateAction({
              isTimerModalOpen: true,
              timerModalCustomAction: false,
            }),
          );
        } else {
          notify(t('notifications.something-went-wrong'), 'error');
        }
        updateCurrentUser(updateAction({ isLoading: false }));
      });
  };

  const nextStepActions = () => {
    updateCurrentUser(
      updateAction({
        currentStep: 6,
        finishedStep: currentUser.finishedStep > 5 ? currentUser.finishedStep : 5,
      }),
    );

    history.push('/maksmine');
  };

  const backStepActions = () => {
    updateCurrentUser(
      updateAction({
        currentStep: 4,
      }),
    );

    history.push('/transport');
  };

  useEffect(() => {
    if (currentUser.currentStep > currentUser.finishedStep) {
      updateCurrentUser(updateAction({ isLoading: true }));
      getData();
    }
  }, []);

  return currentUser.selectedTruckType.id === 'courier' ? (
    <Box className='contacts'>
      <div className='flex justify-content-between m-b-32'>
        <div className='flex col m-b-32'>
          <h5 className='body-text-3 m-b-28'>{t('step-5.courier')}</h5>
        </div>
      </div>

      <StepsNav nextStepAction={() => nextStepActions()} backStepAction={() => backStepActions()} />
    </Box>
  ) : null;
};
