import Box from '@material-ui/core/Box';
import React from 'react';

export const BanksRadio = ({ name, value, checked, onChange, image, specialClasses }) => {
  return (
    <label className={`banks-radio ${specialClasses}`}>
      <input type='radio' name={name} value={value} checked={checked} onChange={() => onChange()} />
      <Box className='flex col justify-content-center align-items-center checkmark'>
        <img src={image} />
      </Box>
    </label>
  );
};
