import { Reducer, useReducer } from 'react';

import { simpleReducer } from '../../reducers/simple_reducer';

export const initialState = {
  isPageViewStarted: false,

  isLoading: false,
  isTimerStarted: false,

  isLoadingWithParams: false,
  preselectedCategoryId: null as any,
  preselectedProductId: null as any,
  preselectedUnitId: null as any,
  preselectedPackingTypeId: null as any,
  preselectedSupplierId: null as any,

  currentStep: 1,
  finishedStep: 0,

  unit: null as Unit | null,
  order: null as any,

  // STEP 1 CATEGORY
  categories: [] as any[],
  selectedCategory: null as any,

  // STEP 2 PRODUCT/UNIT
  products: [] as any[],
  popularUnits: [] as any[],
  selectedProduct: null as any,
  selectedUnit: null as any,

  // STEP 3 ADDRESS
  addresses: [] as any[],
  addressFieldErrors: false,

  // STEP 4 SUPPLIER
  selectedPackingType: null as any,
  selectedQuantity: 0,
  selectedSupplier: null as any,
  currentMinOrder: 0,
  currentMaxOrder: 0,
  bestOfferId: null as null | number,

  // STEP 5 DELIVERY
  truckTypes: [] as any[],
  selectedTruckType: null as any,
  trucks: [] as any[],
  quantityPerTruck: [] as any[],
  quantityPerTruckJSON: null as any,
  selfPickupDistance: null as any,
  selfPickupInfo: null as any,
  courierTypes: null as any,
  courierInfo: null as any,
  supplierTruckTypes: null as any,
  changedQuantity: false,

  // STEP 6 TIMING
  supplierSelfPickUp: null as any,
  availableSlots: null as any,
  selectedDate: null as any,
  selectedTimeSlot: null as any,
  isBusinessUser: false,
  isUserDraft: false,

  // STEP 7 CHECKOUT

  isDraftUser: false,
  allowNotifications: false,

  checkoutContactFields: {
    name: '',
    surname: '',
    email: '',
    phone: '',
    businessName: '',
    businessRegistryCode: '',
    businessVat: '',
  },

  paymentMethod: '',

  banks: [] as any[],
  selectedBank: null as any,
  period: null as any,
  mapZoomLevel: 18,

  mainAddressId: null as any,

  billingLikeShipping: true,

  checkoutFieldErrors: false,

  shippingAddress: {
    address: '',
    mapPosition: { lat: 59.179282, lng: 24.754781 },
  },

  // ADDRESS MAP STATE
  preselectedAddress: false,
  shippingAddressFields: {
    country: '',
    region: '',
    city: '',
    street: '',
    number: '',
    zip: '',
    comment: '',
  },

  billingAddress: {
    address: '',
    mapPosition: { lat: 59.179282, lng: 24.754781 },
  },

  billingAddressFields: {
    country: '',
    region: '',
    city: '',
    street: '',
    number: '',
    zip: '',
    comment: '',
  },

  isDeliveryModalOpen: false,
  isTimerModalOpen: false,
  isImageModalOpen: false,
  isSuppDescModalOpen: false,
  isUnitDescModalOpen: false,
  timerModalCustomAction: true,
  unitReadMoreId: null as any,
  estonianDate: '',
  isloggedIn: false,

  // ORDER RESULT
  orderId: null as any,
  profileUrl: null as any,
  orderStatus: null as any,
};

export function useUserReducer(optsState = {}) {
  const initialMergedState = { ...initialState, ...optsState };
  const [state, dispatch] = useReducer<Reducer<InitialState, InitialState>>(
    simpleReducer,
    initialMergedState,
  );
  return [state, dispatch] as const;
}

// Types

export type InitialState = typeof initialState;

export type Unit = {
  id: number;
  name: string;
  short_description: string; //html
  cutted_description: string;
  description: null | string; // html
  image: string; // url
  packing_types: PackingType[];
  suppliers: Supplier[];
};

export type PackingType = {
  id: number;
  name: string;
  pluralized_name: string;
  weight: number;
  volume: number; // float
  range_step: number; // float
  by_weight: boolean;
  supplier: number; // id
};

export type Supplier = {
  id: number;
  name: string;
  popular: boolean;
  supplier_distance: number;
  supplier_unit_packing_types: SUPT[];
};

// suplier unit packing type
export type SUPT = {
  id: number;
  supplier_unit_id: number;
  packing_type_id: number;
  packing_type_name: string;
  packing_type_weight: number;
  packing_type_volume: number;
  packing_type_by_weight: boolean;
  packing_type_range_step: number;
  packing_type_pluralized_name: string;
  min_order: number;
  max_order: number;
  price: number;
  discount_price: null | number;
  available: boolean;
  self_pickup: null | boolean;
  delivery_available: boolean;
  delivery_info: {
    price: number | null; // price of delivery
    date: string | null; // '19 september 2023'
    normal_date: string | null; // '2023-09-19'
  };
  courier_available: boolean;
  courier_info: null | {
    one_packing_price: string;
    places: number;
    full_price: string;
  };
  best_price?: number;
  supplier: number; // id
};
