import Box from '@material-ui/core/Box';
import { Image } from '@material-ui/icons';
import React from 'react';
import { useTranslation } from 'react-i18next';

export const PaymentMethodRadio = ({
  title,
  name,
  value,
  checked,
  onChange,
  disabled,
  image,
  specialClasses,
}) => {
  const { t } = useTranslation();

  return (
    <label className={`payment-radio ${specialClasses}`}>
      <input
        type='radio'
        name={name}
        disabled={disabled}
        value={value}
        checked={checked}
        onChange={() => onChange()}
      />
      <Box className='checkmark'>
        <span className={`flex col ${disabled ? 'text-secondary' : ''}`}>
          {title}
          {disabled && <span className='body-text-5'>{t('step-6.out-of-limits')}</span>}
        </span>
        {image && (
          <Box className='payment-logo'>
            <img src={image} alt={name} />
          </Box>
        )}
      </Box>
    </label>
  );
};
