import {
  Box,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  SvgIcon,
} from '@material-ui/core';
import { Clear, EditOutlined, RadioButtonUnchecked } from '@material-ui/icons';
import { object } from 'prop-types';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

import API from '../../../../API';
import { updateAction } from '../../../../action_creators/simple_actions';
import { ReactComponent as CheckedIcon } from '../../../../assets/icons/checkboxe-checked.svg';
import { GoogleMapsAPI } from '../../../../client-config';
import { useNotify } from '../../../../hooks/use_notify';
import { CurrentUserProviderContext } from '../../../current_user_provider';
import { ConfirmModal } from '../../../modals';
import { AddressModal } from './modal';

export const Address = ({ address }) => {
  const { currentUser, updateCurrentUser } = useContext(CurrentUserProviderContext);
  const [notify] = useNotify();
  const { t } = useTranslation();

  const [newAddressShow, setNewAddressShow] = useState(false);
  const [confirmShow, setConfirmShow] = useState(false);

  const defaultAddress = {
    address: '',
    mapPosition: { lat: 59.179282, lng: 24.754781 },
  };

  const defaultAddressFields = {
    country: '',
    region: '',
    city: '',
    street: '',
    number: '',
    zip: '',
    comment: '',
  };

  const changeDefaultAddress = (id) => {
    updateCurrentUser(updateAction({ isLoading: true }));
    API.patch(`/api/v1/orders/addresses/set_default/${id}`, {
      order: currentUser.order,
    })
      .then((res) => {
        const data = res.data.data;

        document.dispatchEvent(new Event('shippingAddressUpdated'));
        updateCurrentUser(
          updateAction({
            isLoading: false,
            order: data.order,
            addresses: data.addresses,
          }),
        );
      })
      .catch(() => {
        notify(t('notifications.something-went-wrong'), 'error');
        updateCurrentUser(updateAction({ isLoading: false }));
      });
  };

  const onAddressSelect = () => {
    if (address.id === currentUser.mainAddressId) return;

    updateCurrentUser(updateAction({ mainAddressId: address.id }));
    changeDefaultAddress(address.id);
  };

  const onEditAddressClick = (e) => {
    e.stopPropagation();
    updateCurrentUser(
      updateAction({
        billingLikeShipping: address.billing_like_shipping,

        shippingAddress: {
          address: address.google_autocomplete,
          mapPosition: { lat: Number(address.latitude), lng: Number(address.longitude) },
        },

        shippingAddressFields: {
          country: address.country,
          region: address.region,
          city: address.city,
          street: address.street,
          number: address.house,
          zip: address.zip,
          comment: address.comment,
        },

        billingAddress: {
          address: address.billing_google_autocomplete,
        },

        billingAddressFields: {
          street: address.billing_street,
          country: address.billing_country,
          region: address.billing_region,
          city: address.billing_city,
          number: address.billing_house,
          zip: address.billing_zip,
          comment: '',
        },
      }),
    );
    setNewAddressShow(true);
  };

  const onDeleteAddressClick = (e) => {
    e.stopPropagation();
    setConfirmShow(true);
  };

  const handleConfirmDeleteClose = (choise) => {
    setConfirmShow(false);
    if (choise) {
      updateCurrentUser(updateAction({ isLoading: true }));

      API.delete(`/api/v1/orders/addresses/${address.id}`, {
        data: {
          order: currentUser.order,
        },
      })
        .then((res) => {
          const data = res.data.data;

          updateCurrentUser(
            updateAction({
              isLoading: false,
              order: data.order,
              addresses: data.user_addresses,
            }),
          );
        })
        .catch((e) => {
          console.error(e);
          notify(t('notifications.something-went-wrong'), 'error');
          updateCurrentUser(updateAction({ isLoading: false }));
        });
    }
  };

  const onSubmit = (main) => {
    API.put(`/api/v1/orders/addresses/${address.id}`, {
      order: currentUser.order,
      new_address: {
        main: main,
        google_autocomplete: currentUser.shippingAddress.address,
        longitude: currentUser.shippingAddress.mapPosition.lng,
        latitude: currentUser.shippingAddress.mapPosition.lat,
        street: currentUser.shippingAddressFields.street,
        city: currentUser.shippingAddressFields.city,
        region: currentUser.shippingAddressFields.region,
        country: currentUser.shippingAddressFields.country,
        house: currentUser.shippingAddressFields.number,
        zip: currentUser.shippingAddressFields.zip,
        comment: currentUser.shippingAddressFields.comment,

        billing_google_autocomplete: currentUser.billingAddress.address,
        billing_street: currentUser.billingAddressFields.street,
        billing_city: currentUser.billingAddressFields.city,
        billing_country: currentUser.billingAddressFields.country,
        billing_house: currentUser.billingAddressFields.number,
        billing_region: currentUser.billingAddressFields.region,
        billing_zip: currentUser.billingAddressFields.zip,
        billing_like_shipping: currentUser.billingLikeShipping,
      },
    })
      .then((res) => {
        const data = res.data.data;

        updateCurrentUser(
          updateAction({
            order: data.order,
            addresses: data.user_addresses,
            mainAddressId: data.user_addresses.length
              ? data.user_addresses.find((item) => item.main === true).id
              : null,
          }),
        );
      })
      .catch((e) => {
        console.error(e);
        notify(t('notifications.something-went-wrong'), 'error');
      })
      .finally(() => {
        document.dispatchEvent(new Event('shippingAddressUpdated'));

        updateCurrentUser(
          updateAction({
            isLoading: false,

            billingLikeShipping: true,

            shippingAddress: defaultAddress,
            shippingAddressFields: defaultAddressFields,
            billingAddress: defaultAddress,
            billingAddressFields: defaultAddressFields,
          }),
          setNewAddressShow(false),
        );
      });
  };

  const isSelected = (addressId) => addressId === currentUser.mainAddressId;

  return (
    <>
      <ListItem
        className='btn-address p-y-10'
        classes={{ selected: 'selected' }}
        selected={isSelected(address.id)}
        component={Paper}
        elevation={isSelected(address.id) ? 2 : 0}
        button
        onClick={onAddressSelect}
      >
        <ListItemIcon>
          {isSelected(address.id) ? (
            <SvgIcon className='rounded p-1' component={CheckedIcon} />
          ) : (
            <RadioButtonUnchecked />
          )}
        </ListItemIcon>

        <ListItemText primary={address.google_autocomplete} />

        {!isSelected(address.id) && (
          <IconButton onClick={onDeleteAddressClick}>
            <Clear color='error' />
          </IconButton>
        )}
        <IconButton color='primary' onClick={onEditAddressClick}>
          <EditOutlined />
        </IconButton>
      </ListItem>
      <ConfirmModal open={confirmShow} onClose={handleConfirmDeleteClose} />
      <AddressModal
        googleMapURL={`https:maps.googleapis.com/maps/api/js?key=${GoogleMapsAPI}&libraries=places&language=et`}
        loadingElement={<Box />}
        show={newAddressShow}
        setShow={setNewAddressShow}
        title={t('step-1.edit-address')}
        onSubmit={onSubmit}
        address={address}
      />
    </>
  );
};

Address.propTypes = {
  address: object,
};
