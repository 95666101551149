import { Box } from '@material-ui/core';
import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { CurrentUserProviderContext } from '../../current_user_provider';

export const NavDesktop = ({ specialClass, clickAction }) => {
  const { t } = useTranslation();

  const { currentUser } = useContext(CurrentUserProviderContext);

  const { country, region, city, street, house, zip } = currentUser.order || {};

  const formatTime = (time) => `${time}-${parseInt(time.split(':')[0]) + 1}:00`;

  return (
    <Box width='100%' className='nav-desktop'>
      <button
        className={`btn-sidebar ${specialClass(1)}`}
        onClick={() => clickAction(1, '/kategooriad')}
      >
        {t('steps-names.step-1')}
        {currentUser.selectedCategory ? (
          <Box className='body-text-5 text-secondary step-value'>
            {currentUser.selectedCategory.name}
          </Box>
        ) : (
          ''
        )}
      </button>

      <button className={`btn-sidebar ${specialClass(2)}`} onClick={() => clickAction(2, '/toode')}>
        {t('steps-names.step-2')}
        {currentUser.selectedProduct && currentUser.selectedUnit ? (
          <Box className='body-text-5 text-secondary step-value'>
            {currentUser.selectedProduct.name}, {currentUser.selectedUnit.name}
          </Box>
        ) : (
          ''
        )}
      </button>

      <button
        className={`btn-sidebar ${specialClass(3)}`}
        onClick={() => clickAction(3, '/tootja')}
      >
        {t('steps-names.step-3')}
        {currentUser.selectedSupplier ? (
          <Box className='body-text-5 text-secondary step-value'>
            {currentUser.selectedSupplier.name}
          </Box>
        ) : (
          ''
        )}
      </button>

      <button
        className={`btn-sidebar ${specialClass(4)}`}
        onClick={() => clickAction(4, '/transport')}
      >
        {t('steps-names.step-4')}
        {currentUser.selectedTruckType ? (
          <Box className='body-text-5 text-secondary step-value'>
            {currentUser.selectedTruckType.name}
            {currentUser.quantityPerTruck.length > 1 &&
              `, ${currentUser.quantityPerTruck.length} ${t('step-6.rides')}`}
          </Box>
        ) : (
          ''
        )}
      </button>

      <button className={`btn-sidebar ${specialClass(5)}`} onClick={() => clickAction(5, '/aeg')}>
        {t('steps-names.step-5')}
        {currentUser.selectedTimeSlot ? (
          <Box className='body-text-5 text-secondary step-value'>
            {currentUser.estonianDate}, {formatTime(currentUser.selectedTimeSlot.period)}
          </Box>
        ) : (
          ''
        )}

        {currentUser.selectedTruckType && currentUser.selectedTruckType.id === 'courier' ? (
          <Box className='body-text-5 text-secondary step-value'>{t('step-6.dates-value')}</Box>
        ) : (
          ''
        )}
      </button>

      <button
        className={`btn-sidebar ${specialClass(6)}`}
        onClick={() => clickAction(6, '/maksmine')}
      >
        {t('steps-names.step-6')}
      </button>
    </Box>
  );
};
