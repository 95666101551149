import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  wrapper: {
    position: 'relative',
    margin: 'auto',
    width: '100%',
    height: '100%',
  },
  backdrop: {
    position: 'absolute',
    zIndex: 100,
    backgroundColor: 'rgba(255,255,255, 0.5)',
  },
  progress: {
    color: '#15CD48',
    position: 'absolute',
    top: '50%',
    left: 'calc(50% - 12px)',
    marginTop: -12,
  },
}));
