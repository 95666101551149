import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

export const ConfirmModal = ({ open, onClose, text }) => {
  const { t } = useTranslation();

  return (
    <Dialog open={open}>
      <Box sx={{ px: 3, pt: 3, pb: 1 }}>
        <DialogTitle>{t('modals.confirm?')}</DialogTitle>
        {text && (
          <DialogContent>
            <DialogContentText>{text}</DialogContentText>
          </DialogContent>
        )}
        <DialogActions>
          <Button color='default' onClick={() => onClose(false)}>
            {t('buttons.cancel')}
          </Button>
          <Button color='primary' onClick={() => onClose(true)}>
            {t('buttons.confirm')}
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

ConfirmModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  text: PropTypes.string,
};
