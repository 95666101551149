import PropTypes from 'prop-types';
import React from 'react';

import { updateAction } from '../../action_creators/simple_actions';
import { initialState, useUserReducer } from '../../hooks/use_user_reducer';

export const CurrentUserProviderContext = React.createContext({
  currentUser: initialState,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  updateCurrentUser(_: Partial<typeof initialState>) {},
});

export function CurrentUserProvider({ children }) {
  const [currentUser, currentUserDispatch] = useUserReducer();

  const updateCurrentUser = React.useCallback(
    (user) => currentUserDispatch(updateAction(user)),
    [currentUserDispatch],
  );

  process.env['REACT_APP_SERVER'] !== 'production' && console.log(currentUser);

  return (
    <CurrentUserProviderContext.Provider
      value={{
        currentUser,
        updateCurrentUser,
      }}
    >
      {children}
    </CurrentUserProviderContext.Provider>
  );
}

CurrentUserProvider.displayName = 'CurrentUserProvider';

CurrentUserProvider.propTypes = {
  children: PropTypes.any,
};
