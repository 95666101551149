import Box from '@material-ui/core/Box';
import React from 'react';

export const PayLaterRadio = ({ name, value, checked, onChange, disabled, specialClasses }) => {
  return (
    <label className={`pay-later-radio w-100 ${specialClasses}`}>
      <input
        type='radio'
        name={name}
        value={value}
        disabled={disabled}
        checked={checked}
        onChange={() => onChange()}
      />
      <Box className='flex col justify-content-center text-left checkmark'>
        <span className={disabled ? 'text-secondary' : ''}>{name}</span>
      </Box>
    </label>
  );
};
