import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/core/styles';
import { SnackbarProvider } from 'notistack';
import React, { Suspense } from 'react';
import { render } from 'react-dom';
import { hot } from 'react-hot-loader/root';
import { BrowserRouter as Router } from 'react-router-dom';

import SnackbarCloseButton from './components/buttons/SnackbarCloseButton';
import { CurrentUserProvider } from './components/current_user_provider';
import { ScrollToTop } from './components/scroll_to_top';
import './i18n';
import reportWebVitals from './reportWebVitals';
import routes from './routes';
import './styles/application.scss';
import { theme } from './theme';

const renderApp = (Component) => {
  render(
    <Suspense fallback={''}>
      <Router>
        <ScrollToTop />
        <ThemeProvider theme={theme}>
          <SnackbarProvider
            action={(snackbarKey) => <SnackbarCloseButton snackbarKey={snackbarKey} />}
          >
            <CurrentUserProvider>
              <CssBaseline />
              <Component />
            </CurrentUserProvider>
          </SnackbarProvider>
        </ThemeProvider>
      </Router>
    </Suspense>,
    document.getElementById('root'),
  );
  hot(<Component />);
};

renderApp(routes);

reportWebVitals();
