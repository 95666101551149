import { Box } from '@material-ui/core';
import React, { useContext, useMemo } from 'react';
import { GoogleMap, Marker, withGoogleMap, withScriptjs } from 'react-google-maps';

import { GoogleMapsAPI } from '../../../../client-config';
import { CurrentUserProviderContext } from '../../../current_user_provider';

export const CheckoutMap = () => {
  const { currentUser } = useContext(CurrentUserProviderContext);

  const lat = currentUser.supplierSelfPickUp
    ? Number(currentUser.supplierSelfPickUp.latitude)
    : Number(currentUser.order.latitude);
  const lng = currentUser.supplierSelfPickUp
    ? Number(currentUser.supplierSelfPickUp.longitude)
    : Number(currentUser.order.longitude);

  const AsyncMap = withScriptjs(
    withGoogleMap((props) => (
      <GoogleMap
        google={props.google}
        defaultZoom={currentUser.mapZoomLevel}
        defaultCenter={{
          lat: lat,
          lng: lng,
        }}
        options={{
          disableDefaultUI: true,
        }}
      >
        <Marker
          google={props.google}
          draggable={true}
          position={{
            lat: lat,
            lng: lng,
          }}
        />
        <Marker />
      </GoogleMap>
    )),
  );

  return useMemo(
    () => (
      <AsyncMap
        googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${GoogleMapsAPI}&callback=Function.prototype&libraries=places&language=et`}
        loadingElement={<Box style={{ height: `200px` }} />}
        containerElement={<Box style={{ height: `200px` }} />}
        mapElement={<Box style={{ height: `200px` }} />}
      />
    ),
    [],
  );
};
