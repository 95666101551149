import {
  Box,
  CardActions,
  CardContent,
  CardHeader,
  Checkbox,
  Dialog,
  FormControlLabel,
  IconButton,
} from '@material-ui/core';
import Close from '@material-ui/icons/Close';
import { bool, func, object, string } from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import Autocomplete from 'react-google-autocomplete';
import withScriptjs from 'react-google-maps/lib/withScriptjs';
import { useTranslation } from 'react-i18next';

import { updateAction } from '../../../../action_creators/simple_actions';
import { useAddressError } from '../../../../hooks/use_address_error';
import { useNotify } from '../../../../hooks/use_notify';
import { getGeocodePlace, parsePlaceResult } from '../../../../utils/geocode';
import { CurrentUserProviderContext } from '../../../current_user_provider';
import { BasicRadio } from '../../../inputs/radio/basic_radio';
import { BillingAddress } from './billing_address';
import { AddressMap } from './map';
import { ShippingAddress } from './shipping_address';

export const AddressModal = withScriptjs(({ show, setShow, title, onSubmit, address }) => {
  const { currentUser, updateCurrentUser } = useContext(CurrentUserProviderContext);

  const [notify] = useNotify();
  const { t } = useTranslation();
  const { addressErrors, scrollToError, findErrorField } = useAddressError();

  const [value, setValue] = useState(address?.google_autocomplete ?? '');
  const [main, setMain] = useState(address?.main ?? false);

  const defaultAddress = {
    address: '',
    mapPosition: { lat: 59.179282, lng: 24.754781 },
  };

  const defaultAddressFields = {
    country: '',
    region: '',
    city: '',
    street: '',
    number: '',
    zip: '',
    comment: '',
  };

  const updateShippingAddress = (shippingAddress) => {
    updateCurrentUser(
      updateAction({
        shippingAddress,
      }),
    );
    setValue(shippingAddress.address);
  };

  const updateShippingAddressFields = (shippingAddressFields) => {
    updateCurrentUser(
      updateAction({
        shippingAddressFields,
      }),
    );
  };

  const updateZoom = (zoom) => {
    updateCurrentUser(
      updateAction({
        mapZoomLevel: zoom,
      }),
    );
  };

  const updateCenter = async (position) => {
    try {
      const { address, addressFields, countryShortName } = await getGeocodePlace(position);
      if (countryShortName === 'EE') {
        updateShippingAddress(address);
        updateShippingAddressFields(addressFields);
      } else {
        notify(t('notifications.wrong-location'), 'warning');

        updateShippingAddress(defaultAddress);
        updateShippingAddressFields(defaultAddressFields);
      }
    } catch {
      notify(t('notifications.something-went-wrong'), 'error');

      updateShippingAddress({
        address: '',
        mapPosition: position,
      });
      updateShippingAddressFields(defaultAddressFields);
    }
  };

  const onPlaceSelected = (place) => {
    if (place && place.geometry?.location) {
      const { address, addressFields } = parsePlaceResult(place.geometry.location.toJSON(), place);
      updateShippingAddress(address);
      updateShippingAddressFields(addressFields);
    }
  };

  const handleSubmit = () => {
    const validateFields = (fields, errorRefs) => {
      const errorKey = findErrorField(fields);
      if (errorKey) {
        updateCurrentUser(
          updateAction({
            addressFieldErrors: true,
          }),
        );
        notify(t('notifications.address'), 'warning');
        scrollToError(errorRefs[errorKey]);
        return true;
      } else return false;
    };

    if (validateFields(currentUser.shippingAddressFields, addressErrors.shipping)) return;

    if (
      !currentUser.billingLikeShipping &&
      validateFields(currentUser.billingAddressFields, addressErrors.billing)
    )
      return;

    updateCurrentUser(
      updateAction({
        addressFieldErrors: false,
      }),
    );
    onSubmit(main);
  };

  const onClose = () => {
    setShow(false);
    updateCurrentUser(
      updateAction({
        billingLikeShipping: true,
        shippingAddress: defaultAddress,
        shippingAddressFields: defaultAddressFields,
        billingAddress: defaultAddress,
        billingAddressFields: defaultAddressFields,
        addressFieldErrors: false,
      }),
    );
  };

  useEffect(() => {
    if (show) setValue(address?.google_autocomplete ?? '');
  }, [show]);

  return (
    <Dialog
      open={show}
      onClose={onClose}
      className='address-modal'
      classes={{ paper: 'card' }}
      fullWidth
      maxWidth='md'
      disableEscapeKeyDown
    >
      <CardHeader
        action={
          <IconButton onClick={onClose}>
            <Close />
          </IconButton>
        }
        title={title}
        subheader={t('step-1.search-location')}
      />
      <CardContent className='card-content'>
        <label className='flex col form-input search m-t-8 m-b-8'>
          <Autocomplete
            placeholder={t('forms.placeholders.start-type-address')}
            onPlaceSelected={onPlaceSelected}
            language='et'
            options={{
              types: [], // empty types means search all types, default cities
              componentRestrictions: { country: 'ee' },
            }}
            value={value}
            onChange={(event) => setValue(event.target.value)}
          />
          <span className='input-icon search' />
        </label>

        <AddressMap
          center={currentUser.shippingAddress.mapPosition}
          updateCenter={updateCenter}
          zoom={currentUser.mapZoomLevel}
          updateZoom={updateZoom}
        />

        <Box className='form w-100'>
          <ShippingAddress
            errorCountry={addressErrors.shipping.country}
            errorRegion={addressErrors.shipping.region}
            errorCity={addressErrors.shipping.city}
            errorStreet={addressErrors.shipping.street}
            errorZip={addressErrors.shipping.zip}
          />

          <Box className='body-text-1 text-bold m-b-8'>{t('step-1.billing-address')}</Box>
          <p className='body-text-1 text-secondary m-b-32'>{t('step-1.billing-address-desc')}</p>

          <Box className='flex col m-b-60'>
            <BasicRadio
              title={t('forms.titles.same-address')}
              name='user-address'
              checked={currentUser.billingLikeShipping}
              onChange={() => {
                updateCurrentUser(
                  updateCurrentUser({
                    billingLikeShipping: !currentUser.billingLikeShipping,
                  }),
                );
              }}
              specialClasses='m-b-32'
            />

            <BasicRadio
              title={t('forms.titles.use-different-address')}
              name='user-address'
              checked={!currentUser.billingLikeShipping}
              onChange={() => {
                updateCurrentUser(
                  updateCurrentUser({
                    billingLikeShipping: !currentUser.billingLikeShipping,
                  }),
                );
              }}
            />
          </Box>
          {!currentUser.billingLikeShipping && (
            <BillingAddress
              errorCountry={addressErrors.billing.country}
              errorRegion={addressErrors.billing.region}
              errorCity={addressErrors.billing.city}
              errorStreet={addressErrors.billing.street}
              errorZip={addressErrors.billing.zip}
            />
          )}
        </Box>
      </CardContent>
      <CardActions className='flex justify-content-between' disableSpacing>
        <FormControlLabel
          control={<Checkbox color='primary' checked={main} onChange={() => setMain(!main)} />}
          label={t('forms.titles.use-this-address-by-default')}
        />
        <Box>
          <button className='btn-outlined m-r-16' role='button' onClick={onClose}>
            {t('buttons.cancel')}
          </button>
          <button className='btn-contained' role='button' onClick={handleSubmit}>
            {t('buttons.save')}
          </button>
        </Box>
      </CardActions>
    </Dialog>
  );
});

AddressModal.propTypes = {
  show: bool,
  setShow: func,
  title: string,
  onSubmit: func,
  address: object,
};
