export const checkPaymentOptions = (amount) => {
  const limit1 = { min: 30, max: 800 }; // 1 payment amount limit
  const limit2 = { min: 75, max: 2500 }; // 2 payments amount limit
  const limit3 = { min: 75, max: 2500 }; // 3 payments amount limit

  const options = [];

  if (amount >= limit1.min && amount <= limit1.max) {
    options.push(1);
  }
  if (amount >= limit2.min && amount <= limit2.max) {
    options.push(2);
  }
  if (amount >= limit3.min && amount <= limit3.max) {
    options.push(3);
  }

  return options.length > 0 ? options : [];
};
