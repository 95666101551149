import { Box } from '@material-ui/core';
import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';

import { updateAction } from '../../action_creators/simple_actions';
import { useWindowSize } from '../../hooks/use_window_size';
import { CurrentUserProviderContext } from '../current_user_provider';
import { NavDesktop } from './nav_desktop';
import { NavMobile } from './nav_mobile';

export const Sidebar = () => {
  const size = useWindowSize();
  const history = useHistory();

  const { currentUser, updateCurrentUser } = useContext(CurrentUserProviderContext);

  const setStep = (num, url) => {
    updateCurrentUser(
      updateAction({
        currentStep: num,
      }),
    );

    history.push(url);
  };

  const checkButtonClass = (num) => {
    switch (true) {
      case num === currentUser.currentStep:
        return 'active';
      case num < currentUser.currentStep:
        return 'finished';
      case num - 1 > currentUser.finishedStep:
        return 'disabled';
    }
  };

  return (
    <Box component='aside' className='sidebar flex col align-items-center nowrap'>
      {size.width > 768 ? (
        <NavDesktop
          specialClass={(num) => checkButtonClass(num)}
          clickAction={(num, url) => setStep(num, url)}
        />
      ) : (
        <NavMobile
          specialClass={(num) => checkButtonClass(num)}
          clickAction={(num, url) => setStep(num, url)}
        />
      )}
    </Box>
  );
};
