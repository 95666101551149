import { Box } from '@material-ui/core';
import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import API from '../../../../API';
import { updateAction } from '../../../../action_creators/simple_actions';
import { useNotify } from '../../../../hooks/use_notify';
import { CurrentUserProviderContext } from '../../../current_user_provider';
import { StepsNav } from '../../../steps_nav';

export const SelfPickup = () => {
  const [notify] = useNotify();
  const { t } = useTranslation();

  const history = useHistory();
  const { currentUser, updateCurrentUser } = useContext(CurrentUserProviderContext);

  const getData = () => {
    API.post('/api/v1/orders/timing/self_pickup', {
      order: {
        ...currentUser.order,
        truck_type_id: 0,
        self_pickup: true,
        courier_delivery: false,
        trucks_quantity: 0,
        distance: currentUser.selfPickupDistance,
      },
    })
      .then((res) => {
        const data = res.data.data;
        updateCurrentUser(
          updateAction({
            supplierSelfPickUp: data.supplier,
            order: data.order,
            isloggedIn: data.logged_in,
            isLoading: false,
          }),
        );
      })
      .catch((err) => {
        if (err.response.status === 401) {
          updateCurrentUser(
            updateAction({
              isTimerModalOpen: true,
              timerModalCustomAction: false,
            }),
          );
        } else {
          notify(t('notifications.something-went-wrong'), 'error');
        }
        updateCurrentUser(updateAction({ isLoading: false }));
      });
  };

  const nextStepActions = () => {
    updateCurrentUser(
      updateAction({
        currentStep: 6,
        finishedStep: currentUser.finishedStep > 5 ? currentUser.finishedStep : 5,
      }),
    );

    history.push('/maksmine');
  };

  const backStepActions = () => {
    updateCurrentUser(
      updateAction({
        currentStep: 4,
      }),
    );

    history.push('/transport');
  };

  useEffect(() => {
    if (currentUser.currentStep > currentUser.finishedStep) {
      updateCurrentUser(updateAction({ isLoading: true }));
      getData();
    }
  }, []);

  return currentUser.supplierSelfPickUp ? (
    <Box className='contacts'>
      <div className='flex justify-content-between m-b-32'>
        <div className='flex col m-b-30'>
          <h5 className='body-text-3 m-b-28'>{t('step-5.timetable')}</h5>
          <ul className='time-table'>
            <li className='flex align-items-center justify-content-between py-12 table-item'>
              <span className='body-text-5 text-uppercase'>{t('step-5.monday')}</span>
              <span className='body-text-1 text-medium'>
                {currentUser.supplierSelfPickUp.schedule.monday}
              </span>
            </li>
            <li className='flex align-items-center justify-content-between py-12 table-item'>
              <span className='body-text-5 text-uppercase'>{t('step-5.tuesday')}</span>
              <span className='body-text-1 text-medium'>
                {currentUser.supplierSelfPickUp.schedule.tuesday}
              </span>
            </li>
            <li className='flex align-items-center justify-content-between py-12 table-item'>
              <span className='body-text-5 text-uppercase'>{t('step-5.wednesday')}</span>
              <span className='body-text-1 text-medium'>
                {currentUser.supplierSelfPickUp.schedule.wednesday}
              </span>
            </li>
            <li className='flex align-items-center justify-content-between py-12 table-item'>
              <span className='body-text-5 text-uppercase'>{t('step-5.thursday')}</span>
              <span className='body-text-1 text-medium'>
                {currentUser.supplierSelfPickUp.schedule.thursday}
              </span>
            </li>
            <li className='flex align-items-center justify-content-between py-12 table-item'>
              <span className='body-text-5 text-uppercase'>{t('step-5.friday')}</span>
              <span className='body-text-1 text-medium'>
                {currentUser.supplierSelfPickUp.schedule.friday}
              </span>
            </li>
            <li className='flex align-items-center justify-content-between py-12 table-item'>
              <span className='body-text-5 text-uppercase'>{t('step-5.saturday')}</span>
              <span className='body-text-1 text-medium'>
                {currentUser.supplierSelfPickUp.schedule.saturday}
              </span>
            </li>
            <li className='flex align-items-center justify-content-between py-12 table-item'>
              <span className='body-text-5 text-uppercase'>{t('step-5.sunday')}</span>
              <span className='body-text-1 text-medium'>
                {currentUser.supplierSelfPickUp.schedule.sunday}
              </span>
            </li>
          </ul>
        </div>

        <div className='flex col m-b-32'>
          <h5 className='body-text-3 m-b-28'>{t('step-5.address')}</h5>

          <div className='flex col company-contacts'>
            <span className='nav-link text-bold m-b-32 text-primary'>
              {currentUser.selectedSupplier.name}
            </span>

            <span className='nav-link with-icon location m-b-32 text-primary'>
              {currentUser.supplierSelfPickUp.address}
            </span>
            <h5 className='body-text-3 m-b-28'>{t('step-5.next')}</h5>
          </div>
        </div>
      </div>

      <StepsNav nextStepAction={() => nextStepActions()} backStepAction={() => backStepActions()} />
    </Box>
  ) : null;
};
