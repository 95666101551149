import { Box, Card, Tooltip } from '@material-ui/core';
import { HelpOutlineOutlined } from '@material-ui/icons';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import API from '../../../API';
import { updateAction } from '../../../action_creators/simple_actions';
import { useNotify } from '../../../hooks/use_notify';
import { CurrentUserProviderContext } from '../../current_user_provider';
import { ProductRadio } from '../../inputs';
import { ImageModal } from '../../modals';
import { UnitDescriptionModal } from '../../modals/unit_desc_modal/unit_description_modal';
import { StepTitle } from '../../step_title';
import { StepsNav } from '../../steps_nav';

export const Product = () => {
  const { t } = useTranslation();
  const [notify] = useNotify();
  const history = useHistory();

  const unitRef = useRef();

  const { currentUser, updateCurrentUser } = useContext(CurrentUserProviderContext);

  const [hiddenProducts, setHiddenProducts] = useState([]);

  const [selectedImage, setSelectedImage] = useState('');

  const checkHidden = (id) => hiddenProducts.includes(id);

  const disableClose = (id) =>
    currentUser.isLoadingWithParams && currentUser.preselectedProductId === id;

  const toggleSeeMore = (id) =>
    checkHidden(id)
      ? setHiddenProducts(hiddenProducts.filter((item) => item !== id))
      : setHiddenProducts([...hiddenProducts, id]);

  const clearingNextStepState = () => {
    updateCurrentUser(
      updateAction({
        isLoadingWithParams: false,

        unit: null,
        isTimerStarted: false,
        finishedStep: 1,

        selectedPackingType: null,
        selectedQuantity: 0,
        selectedSupplier: null,

        currentMinOrder: 0,
        currentMaxOrder: 0,

        truckTypes: [],
        selectedTruckType: null,
        trucks: [],
        quantityPerTruck: [],

        supplierSelfPickUp: null,
        availableSlots: null,
        selectedDate: null,
        selectedTimeSlot: null,

        banks: [],
      }),
    );
  };

  const nextStepActions = () => {
    if (currentUser.selectedUnit) {
      updateCurrentUser(
        updateAction({
          currentStep: 3,
          finishedStep: currentUser.finishedStep > 2 ? currentUser.finishedStep : 2,
        }),
      );

      history.push('/tootja');
    } else {
      notify(t('notifications.product'), 'warning');
    }
  };

  const backStepActions = () => {
    updateCurrentUser(
      updateAction({
        currentStep: 1,
        isLoadingWithParams: false,
        preselectedCategoryId: null,
        preselectedProductId: null,
        preselectedUnitId: null,
      }),
    );

    history.push('/kategooriad');
  };

  const sortByDistance = (products) => {
    return products.sort((a, b) => {
      const distanceA = a.units[0].distance;
      const distanceB = b.units[0].distance;

      if (distanceA < distanceB) return -1;
      if (distanceA > distanceB) return 1;
      return 0;
    });
  };

  const searchProductById = (arr, id) => arr.find((product) => product.id === id);
  const searchUnitById = (arr, id) => arr.find((unit) => unit.id === id);

  const getData = () => {
    const addressData = !currentUser.addresses.length
      ? {
          street: currentUser.shippingAddressFields.street,
          city: currentUser.shippingAddressFields.city,
          region: currentUser.shippingAddressFields.region,
          country: currentUser.shippingAddressFields.country,
          house: currentUser.shippingAddressFields.number,
          zip: currentUser.shippingAddressFields.zip,
          comment: currentUser.shippingAddressFields.comment,

          google_autocomplete: currentUser.shippingAddress.address,
          latitude: currentUser.shippingAddress.mapPosition.lat,
          longitude: currentUser.shippingAddress.mapPosition.lng,
        }
      : {};

    API.post('/api/v1/orders/products', {
      order: {
        ...currentUser.order,
        ...addressData,
        category_id: currentUser.selectedCategory.id,
        category_name: currentUser.selectedCategory.name,
        product_id: 0,
      },
    })
      .then((res) => {
        const data = res.data.data;

        updateCurrentUser(
          updateAction({
            products: sortByDistance(data.products),
            popularUnits: data.popular_units,
            order: data.order,
            isloggedIn: data.logged_in,
            isLoading: false,
          }),
        );

        setHiddenProducts(
          data.products
            .filter((product) => product.units.length > 6 && !disableClose(product.id))
            .map((a) => a.id),
        );
      })
      .catch((err) => {
        if (err.response?.status === 401) {
          updateCurrentUser(
            updateAction({
              isTimerModalOpen: true,
              timerModalCustomAction: false,
            }),
          );
        } else {
          notify(t('notifications.something-went-wrong'), 'error');
        }
        updateCurrentUser(updateAction({ isLoading: false }));
      });
  };

  useEffect(() => {
    if (currentUser.currentStep > currentUser.finishedStep) {
      updateCurrentUser(updateAction({ isLoading: true }));
      getData();
    }
  }, []);

  useEffect(() => {
    if (currentUser.currentStep > currentUser.finishedStep) {
      if (
        currentUser.products.length &&
        currentUser.isLoadingWithParams &&
        currentUser.preselectedProductId
      ) {
        const product = searchProductById(currentUser.products, currentUser.preselectedProductId);

        if (product) {
          const unit = product && searchUnitById(product.units, currentUser.preselectedUnitId);

          updateCurrentUser(
            updateAction({
              selectedProduct: product,
            }),
          );
          if (unit) {
            updateCurrentUser(
              updateAction({
                selectedUnit: unit,
              }),
            );
          }
        }
      }
    }
  }, [currentUser.products]);

  useEffect(() => {
    if (currentUser.currentStep > currentUser.finishedStep && currentUser.isLoadingWithParams) {
      if (currentUser.selectedUnit) {
        updateCurrentUser(
          updateAction({
            finishedStep: currentUser.finishedStep > 2 ? currentUser.finishedStep : 2,
          }),
        );
        unitRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }
  }, [currentUser.selectedUnit]);

  return (
    <>
      <Box className='product-step'>
        <StepTitle title={t('steps-names.step-2')} num='2' />

        {!!currentUser.popularUnits.length && (
          <Box className={`flex col align-items-start m-b-60`}>
            <Box className='flex justify-content-center align-items-center m-b-16'>
              <Box className='text-medium m-r-4'>{t('step-2.nearest-popular-product')}</Box>
              <Tooltip
                title={t('step-2.nearest-popular-product-tooltip')}
                placement='top-start'
                arrow
                enterTouchDelay={10}
              >
                <Box className='display-inline-flex p-4'>
                  <HelpOutlineOutlined color='primary' />
                </Box>
              </Tooltip>
            </Box>
            <Card variant='outlined' square className='flex w-100 units-list card-secondary'>
              {currentUser.popularUnits.map((unit) => (
                <ProductRadio
                  domRef={unit.id === currentUser.preselectedUnitId ? unitRef : null}
                  key={`pop_unit_${unit.id}`}
                  title={unit.name}
                  desc={unit.cutted_description}
                  price={unit.price_from ? `${unit.price_from} €` : ''}
                  distance={unit.distance}
                  popular
                  value={unit.id}
                  checked={
                    currentUser.selectedUnit ? currentUser.selectedUnit.id === unit.id : false
                  }
                  onChange={() => {
                    clearingNextStepState();
                    updateCurrentUser(
                      updateAction({
                        selectedUnit: unit,
                        selectedProduct: {
                          id: unit.product_id,
                          name: unit.product_name,
                          slug: unit.product_slug,
                        },
                      }),
                    );
                  }}
                  image={unit.image}
                  onImageClick={() => {
                    setSelectedImage(unit.image);
                    updateCurrentUser(
                      updateAction({
                        isImageModalOpen: !currentUser.isImageModalOpen,
                      }),
                    );
                  }}
                  specialClasses={`m-b-16 m-r-8`}
                  productName={unit.product_name}
                  showDesc={false}
                />
              ))}
            </Card>
          </Box>
        )}
        {currentUser.products &&
          currentUser.products.map((product) => (
            <Box className={`flex col align-items-start m-b-60 `} key={`product_${product.id}`}>
              <Box className='text-medium m-b-16'>{product.name}</Box>
              <Box
                className={`flex w-100 m-b-16 units-list ${
                  checkHidden(product.id) ? 'closed' : ''
                }`}
              >
                {product.units.map((unit) => (
                  <ProductRadio
                    domRef={unit.id === currentUser.preselectedUnitId ? unitRef : null}
                    key={`unit_${unit.id}`}
                    title={unit.name}
                    desc={unit.cutted_description}
                    price={unit.price_from ? `${unit.price_from} €` : ''}
                    value={unit.id}
                    checked={
                      currentUser.selectedUnit ? currentUser.selectedUnit.id === unit.id : false
                    }
                    onChange={() => {
                      clearingNextStepState();
                      updateCurrentUser(
                        updateAction({
                          selectedUnit: unit,
                          selectedProduct: product,
                        }),
                      );
                    }}
                    image={unit.image}
                    onImageClick={() => {
                      setSelectedImage(unit.image);
                      updateCurrentUser(
                        updateAction({
                          isImageModalOpen: !currentUser.isImageModalOpen,
                        }),
                      );
                    }}
                    specialClasses={`m-b-16 m-r-8`}
                  />
                ))}
              </Box>
              {product.units.length > 6 && checkHidden(product.id) && (
                <button
                  className={`body-text-8 text-green btn-see-more ${
                    !checkHidden(product.id) ? 'active' : ''
                  }`}
                  onClick={() => toggleSeeMore(product.id)}
                >
                  {t('buttons.see-more')}
                  <span className='icon'></span>
                </button>
              )}

              {product.units.length > 6 && !checkHidden(product.id) && (
                <button
                  className={`body-text-8 text-green btn-see-more ${
                    !checkHidden(product.id) ? 'active' : ''
                  }`}
                  onClick={() => toggleSeeMore(product.id)}
                >
                  {t('buttons.see-less')}
                  <span className='icon'></span>
                </button>
              )}
            </Box>
          ))}
        <ImageModal
          isOpen={currentUser.isImageModalOpen}
          closeAction={() =>
            updateCurrentUser(
              updateAction({
                isImageModalOpen: !currentUser.isImageModalOpen,
              }),
            )
          }
          src={selectedImage}
        />

        {currentUser.products && (
          <UnitDescriptionModal
            isOpen={currentUser.isUnitDescModalOpen}
            closeAction={() =>
              updateCurrentUser(
                updateAction({
                  isUnitDescModalOpen: !currentUser.isUnitDescModalOpen,
                }),
              )
            }
          />
        )}
      </Box>

      <StepsNav nextStepAction={() => nextStepActions()} backStepAction={() => backStepActions()} />
    </>
  );
};
