import { Box } from '@material-ui/core';
import React, { useContext, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import Slider from 'react-slick';

import { CurrentUserProviderContext } from '../../current_user_provider';

export const NavMobile = ({ specialClass, clickAction }) => {
  const slider = useRef();
  const { t } = useTranslation();
  const { currentUser, updateCurrentUser } = useContext(CurrentUserProviderContext);

  const sliderSettings = {
    dots: false,
    arrows: false,
    infinite: false,
    speed: 300,
    slidesToShow: 1,
    centerMode: true,
    slidesToScroll: 1,
    variableWidth: true,
  };

  useEffect(() => {
    slider.current.slickGoTo(currentUser.currentStep - 1);
  }, [currentUser.currentStep]);

  return (
    <Box className='nav-mobile'>
      <Slider ref={slider} {...sliderSettings}>
        <button
          className={`btn-sidebar mobile ${specialClass(1)}`}
          onClick={() => clickAction(1, '/kategooriad')}
        >
          {t('steps-names.step-1')}
        </button>

        <button
          className={`btn-sidebar mobile ${specialClass(2)}`}
          onClick={() => clickAction(2, '/toode')}
        >
          {t('steps-names.step-2')}
        </button>

        <button
          className={`btn-sidebar mobile ${specialClass(3)}`}
          onClick={() => clickAction(3, '/tootja')}
        >
          {t('steps-names.step-3')}
        </button>

        <button
          className={`btn-sidebar mobile ${specialClass(4)}`}
          onClick={() => clickAction(4, '/transport')}
        >
          {t('steps-names.step-4')}
        </button>

        <button
          className={`btn-sidebar mobile ${specialClass(5)}`}
          onClick={() => clickAction(5, '/aeg')}
        >
          {t('steps-names.step-5')}
        </button>

        <button
          className={`btn-sidebar mobile ${specialClass(6)}`}
          onClick={() => clickAction(6, '/maksmine')}
        >
          {t('steps-names.step-6')}
        </button>
      </Slider>
    </Box>
  );
};
