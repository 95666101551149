import { useRef } from 'react';

export const useAddressError = () => {
  const addressErrors = {
    shipping: {
      country: useRef(null),
      region: useRef(null),
      city: useRef(null),
      street: useRef(null),
      zip: useRef(null),
    },

    billing: {
      country: useRef(null),
      region: useRef(null),
      city: useRef(null),
      street: useRef(null),
      zip: useRef(null),
    },
  };

  const scrollToError = (errorRef) =>
    errorRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });

  const findErrorField = (fields) =>
    Object.keys(fields).find((key) => fields[key] === '' && !['number', 'comment'].includes(key));

  return { addressErrors, scrollToError, findErrorField };
};
