import { Box } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { useWindowSize } from '../../hooks/use_window_size';

export const ContactInfo = () => {
  const { t } = useTranslation();
  const size = useWindowSize();

  return (
    <Box className='contact-info'>
      {size.width >= 1024 ? (
        <Box className='text-secondary text-bold question'>
          {t('modals.contact-info-desk-1')}
          <br />
          {t('modals.contact-info-desk-2')}
        </Box>
      ) : (
        <Box className='text-secondary text-bold question'>{t('modals.contact-info-mob')}</Box>
      )}

      <Box className='flex'>
        <a
          href='mailto:info@roadly.ee'
          className='nav-link with-icon email light opacity-hover text-secondary m-r-28'
        >
          info@roadly.ee
        </a>
        <a
          href='tel:+372 5477 0555'
          className='nav-link with-icon opacity-hover phone light text-secondary'
        >
          +372 5477 0555
        </a>
      </Box>
    </Box>
  );
};
