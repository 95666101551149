import { Backdrop, Box, CircularProgress } from '@material-ui/core';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import { useStyles } from './styles';

export function LoadingWrapper({
  loading,
  size = 50,
  thickness = 5,
  children,
  className,
  ...other
}) {
  const styles = useStyles();

  return (
    <Box {...other} className={classNames(styles.wrapper, className)}>
      {children}
      <Backdrop open={loading} className={styles.backdrop}>
        <CircularProgress className={styles.progress} size={size} thickness={thickness} />
      </Backdrop>
    </Box>
  );
}

LoadingWrapper.displayName = 'LoadingWrapper';

LoadingWrapper.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  loading: PropTypes.bool.isRequired,
  size: PropTypes.number,
  thickness: PropTypes.number,
};
