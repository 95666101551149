import React from 'react';

export const CategoryRadio = ({ title, name, value, checked, onChange }) => {
  return (
    <label className='flex col justify-content-between cursor-pointer category-radio'>
      <input type='radio' name={name} value={value} checked={checked} onChange={() => onChange()} />
      <span className='checkmark' />
      <span className='body-text-4 text-extra-bold text-white'>{title}</span>
    </label>
  );
};
