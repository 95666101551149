import { Box } from '@material-ui/core';
import React, { Dispatch, SetStateAction, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { CurrentUserProviderContext } from '../../current_user_provider';

export const SelectQuanity = (props: {
  quantityError: string;
  setQuantityError: Dispatch<SetStateAction<string>>;
  onQuantityChange: (quantity: number) => void;
  clearingNextStepState: () => void;
}) => {
  const { setQuantityError, quantityError, clearingNextStepState, onQuantityChange } = props;

  const { t } = useTranslation();
  const { currentUser } = useContext(CurrentUserProviderContext);

  const checkM3 = (name: string) => (name === 'm³' || name === 'm3' ? 'm³' : '');

  const increaseQuantity = () => {
    const quantity = currentUser.selectedQuantity;
    const step = Number(currentUser.selectedPackingType.range_step);

    const val = !currentUser.selectedPackingType.by_weight
      ? quantity + step
      : Number((quantity + step).toFixed(1));

    onQuantityChange(val);
  };

  const decreaseQuantity = () => {
    const quantity = currentUser.selectedQuantity;
    const step = Number(currentUser.selectedPackingType.range_step);

    const val = !currentUser.selectedPackingType.by_weight
      ? quantity - step
      : Number((quantity - step).toFixed(1));

    onQuantityChange(val);
  };

  const quantityValidate = (quantity: number) => {
    const step = currentUser.selectedPackingType.range_step;
    const decimals = quantity.toString().split('.')[1];
    const stepDecimal = step.toString().split('.')[1];

    if (!stepDecimal && typeof decimals !== 'undefined') {
      if (decimals !== '0') {
        setQuantityError(
          `${t('step-3.wrong-range')} ${currentUser.selectedPackingType.range_step}t`,
        );
      }
    }

    if (Number(decimals) % Number(stepDecimal)) {
      setQuantityError(`${t('step-3.wrong-range')} ${currentUser.selectedPackingType.range_step}t`);
    }
  };

  const onEnterEnd = (selectedQuantity: number) => {
    const quantity = Math.abs(selectedQuantity);
    const isByWeight = currentUser.selectedPackingType?.by_weight;
    setQuantityError('');
    if (isByWeight) {
      quantityValidate(quantity);
    }

    const qty = isByWeight ? Number(quantity.toFixed(1)) : Math.floor(quantity);

    onQuantityChange(qty);
  };

  return (
    <Box className='flex col align-items-start left-centered-mobile'>
      <Box className='text-secondary m-b-16'>
        {t('step-3.quantity')}
        <span className='text-primary text-medium'>
          {'\u00A0'}
          {currentUser.selectedQuantity}{' '}
          {/* {currentUser.selectedPackingType.by_weight
            ? 't'
            : checkM3(currentUser.selectedPackingType.name)} */}
          {currentUser.selectedQuantity === 1
            ? ` ${currentUser.selectedPackingType.name}`
            : ` ${currentUser.selectedPackingType.pluralized_name}`}
        </span>
      </Box>
      <label className='product-input-number m-b-16'>
        <button
          type='button'
          className='text-green'
          onClick={() => {
            clearingNextStepState();
            decreaseQuantity();
          }}
          disabled={!currentUser.selectedQuantity}
        >
          &ndash;
        </button>
        <Box className='text-extra-bold value'>
          <input
            placeholder='0'
            type='number'
            min='0'
            value={currentUser.selectedQuantity || ''}
            onChange={(event) => {
              const value = Number(event.target.value);
              const selectedQuantity = isNaN(value) ? 0 : value;

              onEnterEnd(selectedQuantity);
              clearingNextStepState();
            }}
          />

          {currentUser.selectedPackingType.by_weight
            ? 't'
            : checkM3(currentUser.selectedPackingType.name)}
        </Box>
        <button
          type='button'
          className='text-green'
          onClick={() => {
            clearingNextStepState();
            increaseQuantity();
          }}
        >
          +
        </button>
      </label>

      <Box className='body-text-5 text-bold text-red error'>{quantityError}</Box>
    </Box>
  );
};
