import { Box, Dialog, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import React from 'react';

export const ImageModal = ({ isOpen, closeAction, src }) => {
  return (
    <Dialog
      onClose={() => closeAction()}
      disableEscapeKeyDown
      fullWidth
      maxWidth='md'
      open={isOpen}
    >
      <Box className='flex justify-content-end'>
        <IconButton onClick={() => closeAction()}>
          <CloseIcon />
        </IconButton>
      </Box>

      <Box className='flex col modal image-modal'>
        <img src={src} />
      </Box>
    </Dialog>
  );
};
