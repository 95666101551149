import each from 'lodash/each';
import isPlainObject from 'lodash/isPlainObject';
import snakeCase from 'lodash/snakeCase';

export function checkErrors(errors, key) {
  return !!errors && !!errors[key] ? errors[key] : '';
}

function convertHashToSnakeCase(hash, ignore = []) {
  const snakeHash = {};
  each(hash, (v, k) => {
    const key = ignore.includes(k) ? k : snakeCase(k);
    if (isPlainObject(v)) {
      snakeHash[key] = convertToSnakeCase(v, ignore);
    } else if (Array.isArray(v)) {
      snakeHash[key] = v.map((el) => (isPlainObject(el) ? convertToSnakeCase(el, ignore) : el));
    } else {
      snakeHash[key] = v;
    }
  });
  return snakeHash;
}

export function convertToSnakeCase(value, ignore = ['_destroy']) {
  if (isPlainObject(value)) {
    return convertHashToSnakeCase(value, ignore);
  } else {
    return snakeCase(value);
  }
}

export function getActiveFilters(columns) {
  return columns
    .filter((col) => !!col.filter && !!col.filterActive)
    .map(({ key, filterValue: { option, values } }) =>
      convertToSnakeCase({
        key: convertToSnakeCase(key),
        option,
        values,
      }),
    );
}

export function parseAttributes(apiData) {
  return apiData?.data?.attributes || apiData?.data || apiData?.attributes || apiData || {};
}

export const capitalize = (str = '') => (str ? str.charAt(0).toUpperCase() + str.slice(1) : null);

export const uniqueObj = (array) => {
  return array.filter((value, index, self) => index === self.findIndex((el) => el.id === value.id));
};

export const scrollToSection = (sectionId) => {
  const element = document.getElementById(sectionId);
  const header = document.querySelector('header.header');
  const sidebar = document.querySelector('aside.sidebar');

  if (element && header) {
    let headerHeight = header.offsetHeight;
    const isMobile = window.innerWidth <= 768;
    headerHeight = isMobile ? headerHeight + sidebar.offsetHeight : headerHeight;

    //const targetTop = element.offsetTop - headerHeight;
    const targetTop = element.offsetTop;
    window.scrollTo({ top: targetTop, behavior: 'smooth' });
  }
};
