import { Box } from '@material-ui/core';
import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect, useHistory, useLocation } from 'react-router';

import { updateAction } from '../../action_creators/simple_actions';
import { ContactInfo } from '../contact_info/contact_info';
import { CurrentUserProviderContext } from '../current_user_provider';
import { Header } from '../header/header';
import { MainLayout } from '../layouts';
import { LoadingWrapper } from '../loading_wrapper';
import { Sidebar } from '../sidebar';
import { Timer } from '../timer';

export function Dashboard({ children }) {
  const location = useLocation();
  const history = useHistory();
  const { t } = useTranslation();

  const { currentUser, updateCurrentUser } = useContext(CurrentUserProviderContext);

  const setTitles = (path) => {
    let currentTitle;
    let currentDesc;

    switch (path) {
      case '/kategooriad':
        currentTitle = t('steps-titles.step-1');
        currentDesc = t('steps-desc.step-1');
        break;
      case '/toode':
        currentTitle = t('steps-titles.step-2');
        currentDesc = t('steps-desc.step-2');
        break;
      case '/tootja':
        currentTitle = t('steps-titles.step-3');
        currentDesc = t('steps-desc.step-3');
        break;
      case '/transport':
        currentTitle = t('steps-titles.step-4');
        currentDesc = t('steps-desc.step-4');
        break;
      case '/aeg':
        currentTitle = t('steps-titles.step-5');
        currentDesc = t('steps-desc.step-5');
        break;
      case '/maksmine':
        currentTitle = t('steps-titles.step-6');
        currentDesc = t('steps-desc.step-6');
        break;

      default:
        currentTitle = 'Order';
    }

    // setting titles for "order_result" page - directly in components

    document.title = currentTitle;
    document.querySelector('meta[name="description"]').setAttribute('content', currentDesc);
    document.querySelector('meta[property="og:title"]').setAttribute('content', currentTitle);
    document.querySelector('meta[property="og:description"]').setAttribute('content', currentDesc);
  };

  const trackPageView = () => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'virtualPageview',
      pageUrl: location.pathname,
    });
  };

  useEffect(() => {
    if (!currentUser.isPageViewStarted && location.pathname === '/kategooriad') {
      trackPageView();
    }

    history.listen(trackPageView);
    setTitles(location.pathname);

    updateCurrentUser(updateAction({ isPageViewStarted: true }));
  }, [window.location.href]);

  return (
    <LoadingWrapper loading={currentUser.isLoading}>
      {location.pathname === '/aitah' ? (
        <MainLayout>{children}</MainLayout>
      ) : (
        <MainLayout>
          <Header />

          <Sidebar />

          <Box className='steps-wrapper'>
            <Box className='step'>
              <Timer />
              {location.pathname !== '/kategooriad' && currentUser.finishedStep === 0 ? (
                <Redirect to='/kategooriad' />
              ) : (
                children
              )}
            </Box>

            {!currentUser.isLoading && <ContactInfo />}
          </Box>
        </MainLayout>
      )}
    </LoadingWrapper>
  );
}
