import React, { useContext, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

import { CurrentUserProviderContext } from '../../current_user_provider';

export function MainLayout({ children }) {
  const { pathname } = useLocation();

  const { currentUser } = useContext(CurrentUserProviderContext);

  const layout = useRef();

  const checkModals =
    currentUser.isTimerModalOpen ||
    currentUser.isDeliveryModalOpen ||
    currentUser.isImageModalOpen ||
    currentUser.isSuppDescModalOpen ||
    currentUser.isUnitDescModalOpen;

  useEffect(() => {
    currentUser.currentStep !== 2 &&
      currentUser.isLoadingWithParams === false &&
      layout.current.scrollIntoView();
  }, [pathname]);

  return (
    <div
      ref={layout}
      className={`main-layout flex justify-content-end ${checkModals && 'no-scroll'}`}
    >
      {children}
    </div>
  );
}
