import { Box } from '@material-ui/core';
import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

import image from '../../../../assets/icons/payment-failed.svg';

export const PaymentFailed = () => {
  const { t } = useTranslation();
  const history = useHistory();

  useEffect(() => {
    document.title = t('steps-titles.failed-payment');
  }, []);

  return (
    <Box className='flex justify-content-center align-items-center thank-you-step'>
      <Box className='flex col align-items-center content'>
        <img src={image} className='step-image m-b-60' />
        <Box className='m-b-16 text-extra-bold title'>{t('thank-you-step.failed')}</Box>
        <Box className='body-text-2 text-bold m-b-28 text-center'>{t('thank-you-step.reason')}</Box>
        <Box className='body-text-3 text-secondary text-center'>
          {t('thank-you-step.try-again')}
          <br />
          {t('thank-you-step.support')}
        </Box>

        <Box className='flex align-items-center text-center m-b-60'>
          <a href='tel:+372 5477 0555' className='body-text-3 text-underline'>
            +372 5477 0555
          </a>
          ,
          <a href='mailto:info@roadly.ee' className='body-text-3 text-underline'>
            info@roadly.ee
          </a>
        </Box>

        <button
          className='btn-contained with-arrow angle-arrow reversed'
          onClick={() => {
            history.push('/kategooriad');
          }}
        >
          {t('buttons.back-to-order')}
        </button>
      </Box>
    </Box>
  );
};
