import { Box, Dialog, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import timer from '../../../assets/icons/clock-regular.svg';
import { CurrentUserProviderContext } from '../../current_user_provider';

export const TimerModal = ({ isOpen, closeAction }) => {
  const { t } = useTranslation();
  const { currentUser } = useContext(CurrentUserProviderContext);

  const defaultAction = () => window.location.reload();

  return (
    <Dialog
      onClose={() => closeAction()}
      disableEscapeKeyDown
      disableScrollLock
      fullWidth
      maxWidth='sm'
      open={isOpen}
    >
      <Box className='flex justify-content-end'>
        <IconButton onClick={() => closeAction()}>
          <CloseIcon />
        </IconButton>
      </Box>

      <Box className='flex col align-items-center modal info-modal'>
        <Box className='flex col align-items-center m-b-32'>
          <img src={timer} className='modal-logo' />
          <h1 className='h1 text-center'>{t('notifications.time-passed')}</h1>
        </Box>

        <button
          className='btn-contained with-arrow angle-arrow reversed'
          onClick={() => (currentUser.timerModalCustomAction ? closeAction() : defaultAction())}
        >
          OK
        </button>
      </Box>
    </Dialog>
  );
};
