import { Box } from '@material-ui/core';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { CurrentUserProviderContext } from '../../current_user_provider';
import { StepTitle } from '../../step_title';
import { Courier } from './courier/courier';
import { SelfPickup } from './self_pickup/self_pickup';
import { Trucks } from './trucks/trucks';

export const Timing = () => {
  const { t } = useTranslation();
  const { currentUser } = useContext(CurrentUserProviderContext);

  const TimingComponent = () => {
    if (currentUser.selectedTruckType.id === 'self_pickup') {
      return <SelfPickup />;
    } else if (currentUser.selectedTruckType.id === 'courier') {
      return <Courier />;
    } else return <Trucks />;
  };

  return (
    <>
      <Box className='timing-step'>
        <StepTitle title={t('steps-names.step-5')} num='5' />

        {TimingComponent()}
      </Box>
    </>
  );
};
