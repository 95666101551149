import { Box } from '@material-ui/core';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import useCountDown from 'react-countdown-hook';

import API from '../../API';
import { updateAction } from '../../action_creators/simple_actions';
import { CurrentUserProviderContext } from '../current_user_provider';
import { TimerModal } from '../modals';

export const Timer = () => {
  const { currentUser, updateCurrentUser } = useContext(CurrentUserProviderContext);

  const initialTime = 120000000;
  const interval = 1000;

  const [timeLeft, { start, pause, resume, reset }] = useCountDown(initialTime, interval);

  const restart = React.useCallback(() => {
    const newTime = 42 * 1000;
    start(newTime);
  }, []);

  const formatTime = () => {
    const duration = moment.duration(timeLeft, 'milliseconds');

    return `${duration.minutes()}:${
      duration.seconds().toString().length === 1 ? '0' : ''
    }${duration.seconds()}`;
  };

  const stopTimer = process.env['REACT_APP_SERVER'] !== 'production' ? '19:00' : '0:01';

  const getData = () => {
    API.get(`/api/v1/orders/delete_order/`)
      .then((res) => {
        window.location.reload();
      })
      .catch((err) => {
        if (err.response.status === 401) {
          window.location.reload();
        }
        updateCurrentUser(updateAction({ isLoading: false }));
      });
  };

  useEffect(() => {
    currentUser.isTimerStarted && start();
    !currentUser.isTimerStarted && reset();
  }, [currentUser.isTimerStarted]);

  useEffect(() => {
    if (formatTime() === '0:01') {
      updateCurrentUser(
        updateAction({
          isTimerStarted: false,
          isTimerModalOpen: true,
        }),
      );
    }
  }, [timeLeft]);

  return (
    <>
      <Box
        className={`body-text-4 text-extra-bold text-red timer ${
          currentUser.isTimerStarted && 'visible'
        }`}
      >
        {formatTime()}
      </Box>
      <TimerModal isOpen={currentUser.isTimerModalOpen} closeAction={() => getData()} />
    </>
  );
};
