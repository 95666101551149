import { Box, BoxProps } from '@material-ui/core';
import React from 'react';

export const StepTitle = ({
  num,
  title,
  sx,
}: {
  num: string;
  title: string;
  sx?: BoxProps['sx'];
}) => {
  return (
    <Box sx={sx} className='step-title flex align-items-center'>
      <span className='order-number flex justify-content-center align-items-center'>{num}</span>
      <h1 className='h1'>{title}</h1>
    </Box>
  );
};
