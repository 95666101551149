import { Box, Tooltip } from '@material-ui/core';
import { HelpOutline } from '@material-ui/icons';
import React, { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { SUPT, Supplier } from '../../../hooks/use_user_reducer';
import { CurrentUserProviderContext } from '../../current_user_provider';

export const SupplierRow = (props: {
  supplier: Supplier;
  index: number;
  showAllSuppliers: boolean;
  findSupplierUnitPackingType: (supplier: Supplier) => SUPT | undefined;
  clearingNextStepState: () => void;
  quantityError: string;
  bestOfferId: number | null;
  nearestOfferId: number | null;
}) => {
  const {
    supplier,
    index,
    showAllSuppliers,
    findSupplierUnitPackingType,
    clearingNextStepState,
    quantityError,
    bestOfferId,
    nearestOfferId,
  } = props;

  const { t } = useTranslation();
  const { currentUser, updateCurrentUser } = useContext(CurrentUserProviderContext);

  const supt = useMemo(() => {
    return findSupplierUnitPackingType(supplier);
  }, [supplier.supplier_unit_packing_types, currentUser.selectedPackingType.id]);

  if (!supt) return null;

  const deliveryInfo = {
    availability: supt.delivery_available,
    price: supt.delivery_info.price,
    date: supt.delivery_info.date,
  };

  const courierInfo = {
    availability: supt.courier_available,
    price: supt.courier_info ? supt.courier_info.one_packing_price : '',
  };

  const selfPickup = supt.self_pickup;

  const finalPrice = supt.discount_price || supt.price;

  const minOrder = supt.min_order;

  const maxOrder = supt.max_order;

  const disabled =
    !supt.available ||
    currentUser.selectedQuantity < minOrder ||
    currentUser.selectedQuantity > maxOrder ||
    quantityError;

  return (
    <label
      className={
        'supplier-radio' +
        (disabled ? ' disabled' : '') +
        (index > 1 && !showAllSuppliers ? ' hidden' : '')
      }
    >
      {bestOfferId === supplier.id && (
        <Box
          className={
            `flex justify-content-center align-items-center ` +
            `body-text-6 text-white text-uppercase text-extra-bold best-offer`
          }
        >
          {t('step-3.best-offer')}
          <Tooltip
            title={t('step-3.best-offer-description')}
            placement='top-start'
            arrow
            enterTouchDelay={100}
          >
            <HelpOutline className='m-l-2 small-icon' />
          </Tooltip>
        </Box>
      )}

      {nearestOfferId === supplier.id && bestOfferId !== supplier.id && (
        <Box
          className={
            `flex justify-content-center align-items-center ` +
            `body-text-6 text-white text-uppercase text-extra-bold best-offer`
          }
        >
          {t('step-3.nearest-offer')}
        </Box>
      )}

      <input
        type='radio'
        name='supplier'
        checked={currentUser.selectedSupplier?.id === supplier.id}
        onChange={() => {
          clearingNextStepState();
          updateCurrentUser({
            selectedSupplier: supplier,
            currentMinOrder: minOrder,
            currentMaxOrder: maxOrder,
          });
        }}
      />
      <Box className='justify-content-between checkmark'>
        <Box className='flex col align-items-start left'>
          <Box className='body-text-1 m-b-8'>
            {supplier.name} ({supplier.supplier_distance} {t('step-3.km-to-you')})
          </Box>
          <Box>
            {deliveryInfo.availability && (
              <Box>
                <Box className='body-text-5 text-secondary with-icon delivery-grey'>
                  <span> {t('step-4.price')} </span>
                  <strong>{deliveryInfo.price ? `${deliveryInfo.price} €` : 'N/A'} </strong>

                  <br />

                  <span className='product-delivery-time'>
                    {t('step-3.cheapest-delivery-date')}
                    <strong> {deliveryInfo.date ?? 'N/A'}</strong>
                  </span>
                </Box>
              </Box>
            )}

            {courierInfo.availability && (
              <Box>
                <Box className='body-text-5 text-secondary with-icon delivery-grey'>
                  {t('step-3.courier-delivery')}
                  <strong> {courierInfo.price ? `${courierInfo.price} €` : 'N/A'} </strong>

                  <br />
                  <span className='product-delivery-time'>
                    {t('step-3.first-free-time')}
                    <strong>{t('step-3.courier-delivery-time')} </strong>
                  </span>
                </Box>
              </Box>
            )}

            {selfPickup && (
              <Box>
                <Box className='body-text-5 text-secondary with-icon self-pickup'>
                  {t('step-3.self-pickup')}
                </Box>
              </Box>
            )}
          </Box>
        </Box>

        <Box className='pricing flex col align-items-end right'>
          <Box className='body-text-2 text-extra-bold m-b-8'>
            <span className='price-full'>
              {currentUser.selectedQuantity < minOrder ||
              currentUser.selectedQuantity > maxOrder ? (
                <Box className='body-text-8 text-secondary text-medium text-end'>
                  {t('step-3.wrong-quantity')}
                </Box>
              ) : (
                `${(finalPrice * currentUser.selectedQuantity).toFixed(2)} €`
              )}
            </span>
          </Box>
          {!disabled && (
            <Box className='body-text-5'>
              {currentUser.selectedQuantity}
              {currentUser.selectedQuantity === 1
                ? ` ${currentUser.selectedPackingType.name}`
                : ` ${currentUser.selectedPackingType.pluralized_name}`}
            </Box>
          )}
        </Box>
      </Box>
    </label>
  );
};
